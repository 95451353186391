import { useEffect, useState } from "react"
import Cookies from "js-cookie"
import Popup from 'reactjs-popup';
import { isMobileSize } from "../../CommonHooks";
import { useNavigate, useParams } from "react-router";
import { useTranslation } from 'react-i18next';

import './index.scss'

let defaultData = {
    racing: false,
    football: false,
    marksix: false
}

const MoblieHomePageTooltips = ({prod, page}) =>{
    const [isShow, setIsShow] = useState(false)
    const {t, i18n} = useTranslation()
    useEffect(()=>{
        let homePageTooltipsStatus = Cookies.get('homePageTooltipsStatus')
        try {
            homePageTooltipsStatus  = JSON.parse(homePageTooltipsStatus)
        } catch (error) {
            homePageTooltipsStatus  = defaultData
        }
        if( ['HOME', 'HOME_RESULTS'].includes(page)  && isMobileSize() && (!homePageTooltipsStatus || !homePageTooltipsStatus?.[prod])){
            if(!homePageTooltipsStatus?.[prod]){
                homePageTooltipsStatus = defaultData
            }
           setTimeout(()=>{
            setIsShow(true)
            document.body.style.overflow= 'hidden'
            document.body.style.position = 'fixed';
            homePageTooltipsStatus[prod] = true
            Cookies.set('homePageTooltipsStatus', JSON.stringify(homePageTooltipsStatus), {expires: 365 * 2})
           }, 500)
        }
    },[page])

    useEffect(()=>{
        if(!isShow){
        document.body.removeAttribute('style')
        }
        
    },[isShow])

    const handleClickClose = () =>{
        setIsShow(false)
        document.body.removeAttribute('style')
    }

    return  <Popup open={isShow} closeOnDocumentClick={false} className="PopupAlert homeTooltipsAlert">
    <div className="MobileHomeTooltips logout-succ-msg-container">
        <div className="msgContent">{t(`LB_HOME_TOOLTIPS_${prod.toUpperCase()}`)}</div>
        <div className="imageContent">
            <img src={require(`../../../../info/Include/images/home_tooltips_${prod}_${i18n.language}.png`)}/>
        </div>
        <div className="close-btn-box">
            <div className="close" onClick={handleClickClose}>
                {t('LB_CLOSE')}
            </div>
        </div>
    </div>
</Popup>
}

export default MoblieHomePageTooltips