import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GetBetslipConfig } from '../Common/ConfigHelper';
import { NewWindow } from '../Common/home-common';
import { GetError } from '../Common/bs_str_table';
import Popup from 'reactjs-popup';
import { useWindowSize } from '../Common/CommonHooks';

export const maxLengthLimit = (val, maxLength = 50) => {
    let pass = true;
    if (val.length > maxLength/2) {
        const cnReg = /([\u4e00-\u9fa5]|[\u3000-\u303F]|[\uFF00-\uFF60])/;
        let length = 0;
        val.split('').forEach((str) => {
            if (pass) {
                if (cnReg.test(str)) {
                    length += 2;
                } else {
                    length++;
                }
            }
            if (length > maxLength) {
                pass = false;
            }
        });
    }
    return pass
};

const LoginEKBAAnswerPopup = ({ onClickConfirm, onClickCancel, onClickLogout, isSsoLogin, loginEKBAQuestion = '', showAnswerErrorMsg = '', APILoading, closeBtnEnabled = false,  setShowLoginLockPopup}) => {
    const [answer, setAnswer] = useState('');
    const { t, i18n } = useTranslation();

    const handleClickConfirm = () => {
        onClickConfirm(answer);
    };

    const handleClickCancel = () => {
        onClickCancel();
    };

    const handleClickLogout = () => {
        onClickLogout();
    }

    const handleClickForgotLoginAnswer = () => {
        const flag = window.confirm(t('LB_BS_LOGIN_FORGOT_ANSWER_MSG'));
        if (flag) {
            const url = t('LB_BS_LOGIN_FORGOT_ANSWER_URL');
            window.location = url;
        }
    };

    const handleClickFindLoginAnswer = () => {
        const url = t('LB_BS_LOGIN_ANSWER_ERROR_URL');
        NewWindow(url, '', 750, 500, 1, 1);
    };

    const forgotEkbaUrl = window.globalConfig.WCIP_URL + GetBetslipConfig()?.urls?.wcip?.forgotEKBA[i18n.language];

    const { isMobileSize } = useWindowSize();
    let mobileStyle = isMobileSize ? 'is-mobile' : 'is-desktop';
    useEffect(() => {
        if (isMobileSize) {
            mobileStyle = 'is-mobile'
        } else {
            mobileStyle = 'is-desktop';
        }
    }, [isMobileSize]);

    useEffect(()=>{
        console.log(showAnswerErrorMsg)
        if(showAnswerErrorMsg.login_status=='423'){
            setShowLoginLockPopup(true)
            handleClickCancel();
        }

    },[showAnswerErrorMsg])
    return (
        <Popup open={true} className={`ekbapopup ${mobileStyle}`} onClose={handleClickCancel}>
            <div className="ekbapopup-container" id='popup-ekba-container'>
                {closeBtnEnabled && (
                    <div className="ekbapopup-close" onClick={handleClickCancel}>
                        &times;
                    </div>
                )}
                <div className="ekbapopup-upper">
                    <div className='ekbapopup-title-description'>
                        <div className="ekbapopup-title">{t('LB_BS_LOGIN_QUESTION')}</div>
                        <div className="ekbapopup-message">{t('LB_EKBAPOPUP_MESSAGE')}</div>
                    </div>
                    
                    <div className="ekbapopup-login-answer-content">
                        <div className="ekbapopup-login-question">{loginEKBAQuestion}</div>
                        <div className={`ekbapopup-answer-input ${showAnswerErrorMsg ? 'showAnswerErrorMsg' : ''}`}>
                            <input
                                autoFocus
                                type="text"
                                maxLength={50}
                                value={answer}
                                onChange={(e) => {maxLengthLimit(e.target.value) && setAnswer(e.target.value)}}
                                onKeyPress={(e) => {
                                    if (e.key === 'Enter' && !showAnswerErrorMsg?.disableEkbaConfirm) {
                                        handleClickConfirm();
                                    }
                                }}
                                readOnly={APILoading}
                            />
                        </div>
                        
                        {showAnswerErrorMsg && showAnswerErrorMsg?.login_status!=='423' ? (
                            <div className="ekbapopup-error-answer">
                                {showAnswerErrorMsg?.retry && [0,1,2,3].includes(showAnswerErrorMsg?.retryCount) ? // retry count
                                <>
                                    <span dangerouslySetInnerHTML={{ __html: t('LB_BS_LOGIN_ANSWER_ERROR1').replace('{FORGOT_EKBA_URL}', forgotEkbaUrl)}} />
                                    <span className="helpLink" onClick={handleClickFindLoginAnswer}>
                                        {t('LB_BS_LOGIN_ANSWER_ERROR2')}
                                    </span>
                                    {t('LB_BS_LOGIN_ANSWER_ERROR3').replace('X', showAnswerErrorMsg.retryCount)}
                                </>
                                :
                                (GetError(showAnswerErrorMsg?.login_status, i18n.language) ? // map with bs_str_table error list
                                <span dangerouslySetInnerHTML={{ __html: GetError(showAnswerErrorMsg?.login_status, i18n.language)}} />
                                :
                                showAnswerErrorMsg?.login_error ? // show error message from txn api
                                <span dangerouslySetInnerHTML={{ __html: showAnswerErrorMsg?.login_error}} />
                                :
                                <span>{t('LB_BS_LOGIN_ERROR_OTHER')}</span>
                                )}
                                
                            </div>
                        ) : null}

                        <div className="ekbapopup-forgot-answer" onClick={handleClickForgotLoginAnswer}>
                            {t('LB_EKBAPOPUP_FORGOT')}
                        </div>
                    </div>
                </div>

                <div className={`button-box ${isSsoLogin?'ekbaBtnArea':''} ekbapopup-btn-grp`}>
                    <div className='row1'>
                        <div className={`cta_yellow ${answer.trim().length<=0?'disabled':''} ${showAnswerErrorMsg?.disableEkbaConfirm?'disabled':''} ${APILoading ? 'disabled' : ''}`} onClick={()=> !APILoading && handleClickConfirm()}>{t('LB_NEXT')}</div>
                    </div>
                    
                    {/* {isSsoLogin ?
                        <div className='leftArea'>
                            <div onClick={handleClickCancel}>{t('LB_BS_LOGIN_CANCEL')}</div>
                            <div className='ssoLogoutBtn' onClick={handleClickLogout}>{t('LB_LOGOUT')}</div>
                        </div>
                    :
                        <div className="bsBgColor cta_brand" onClick={handleClickCancel}>{t('LB_BS_LOGIN_CANCEL')}</div>
                    } */}

                    <div className='row2'>
                        <div className="ekbapopup-cancel" onClick={handleClickCancel}>{t('LB_BS_LOGIN_CANCEL')}</div>
                    </div>
                </div>
            </div>
        </Popup>
    );
};

export default LoginEKBAAnswerPopup;
