import { useEffect, useRef, useState, useContext } from 'react';
import {
    initLoginAPI,
    checkAccountPasswordAPI,
    checkEKBAAnswerAPI,
    getAuthorize,
    getAccessToken,
    renewAccessToken,
    getUserInfo,
    logout,
    revokeSession,
    getUserInfoAdditional,
    initEkbaAPI,
    renewSSO
} from './BetSlipAPI';
import { ConfigContext } from '../../components/Common/ConfigHelper';
import { useTranslation } from 'react-i18next';
import { removeCurrentSessionRecords } from '../Common/CurrentSessionRecords';
import { glassboxSetUserInfo } from '../Common/utils/glassbox';
import { callWAReq } from '../Common/utils/wa';
import { MyBetSlipContext } from './BetSlipHooks';

const getSessionToken = () => {
    const accessToken = window.sessionStorage.getItem('access_token') || '';
    const refreshToken = window.sessionStorage.getItem('refresh_token') || '';

    return {
        access_token: accessToken,
        refresh_token: refreshToken
    };
};

export const getSessionLoginAccount = () => {
    return window.sessionStorage.getItem('account') || '';
};

export const setTokenToSession = (token) => {
    window.sessionStorage.setItem('access_token', token.accessToken);
    window.sessionStorage.setItem('refresh_token', token.refreshToken);
    window.sessionStorage.setItem('id_token', token.idToken);
    token.login_account && window.sessionStorage.setItem('login_account', token.login_account);
};

export const setUserInfoToSession = (userInfo) => {
    window.sessionStorage.setItem('login_firstNameEn', userInfo?.bettingProfile?.personalInfo.firstNameEn);
    window.sessionStorage.setItem('login_firstNameZhHk', userInfo?.bettingProfile?.personalInfo.firstNameZhHk);
    window.sessionStorage.setItem('login_lastNameEn', userInfo?.bettingProfile?.personalInfo.lastNameEn);
    window.sessionStorage.setItem('login_lastNameZhHk', userInfo?.bettingProfile?.personalInfo.lastNameZhHk);
    window.sessionStorage.setItem(
        'login_maskedMobilePhoneNumber',
        userInfo?.bettingProfile?.contactInfo.maskedMobilePhoneNumber
    );
    window.sessionStorage.setItem('login_mobilePhoneNumber', userInfo?.bettingProfile?.contactInfo.mobilePhoneNumber);
    window.sessionStorage.setItem('login_webAccountLoginName', userInfo?.webProfile?.personalInfo?.webAccountLoginName);
};

const setUserInfoAdditionalToSession = (userInfoAdditional) => {
    //console.log(userInfoAdditional);
    window.sessionStorage.setItem('login_memberCategoryCode', userInfoAdditional?.memberCategoryCode);
    window.sessionStorage.setItem('login_age', userInfoAdditional?.age);
    window.sessionStorage.setItem('login_gender', userInfoAdditional?.gender);
    userInfoAdditional?.priorityCardTier &&
        window.sessionStorage.setItem('login_priorityCardTier', userInfoAdditional.priorityCardTier);
};

const removeToken = () => {
    window.sessionStorage.removeItem('access_token');
    window.sessionStorage.removeItem('refresh_token');
    window.sessionStorage.removeItem('id_token');
    window.sessionStorage.removeItem('login_account');
    window.sessionStorage.getItem('login_priorityCardTier') &&
        window.sessionStorage.removeItem('login_priorityCardTier');
};

const getAgeGroup = (age) => {
    // const age = window.sessionStorage.getItem('login_age')
    if (!age || age === 'undefined') return '00';
    if (age < 18) return '01';
    if (age < 26) return '02';
    if (age > 80) return '14';
    let ageGroup = 2;
    ageGroup += Math.ceil((age - 25) / 5);
    if (ageGroup > 10) return ageGroup + '';
    else {
        return '0' + ageGroup;
    }
};
const getGenderCode = (gender) => {
    if (!gender || gender === 'undefined') return '0';
    if (gender === 'M') return '1';
    if (gender === 'F') return '2';
};
const getMemberCode = (member) => {
    if (!member) return '0';
    if (member === 'FULL') return '2';
    if (member === 'RACE') return '3';
    if (member === 'RCIM') return '4';
    return '0';
};

const getPriorityCardCode = (priorityCardCode) => {
    if (!priorityCardCode && priorityCardCode !== '') return '0';
    if (priorityCardCode) return 'Y';
    return 'N';
};

const loginStatus = {
    isBusy: false
};

export const GetLoginStatus = () => {
    return loginStatus;
};

export function SetLoginBusyStatus(status) {
    loginStatus.isBusy = status;
}

export const callLogoutApi = (accessToken) => {
    revokeSession(accessToken);
    logout(accessToken);
};

export const isLogonHigh = () => {
    if (!window.globalConfig.IS_IB) {
        if (
            window.sessionStorage.getItem('access_token') != null &&
            window.sessionStorage.getItem('access_token') != ''
        ) {
            const profile = getProfileByToken(window.sessionStorage.getItem('access_token'));
            if (profile.auth_level >= 50 && (profile.aud == 'JCBW' || profile.aud == 'JCBW_EHS')) {
                return true;
            }
        }
        return false;
    } else {
        return window.sessionStorage.getItem('is_logon') == 1;
    }
};

export const getProfileByToken = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );
    const profile = JSON.parse(jsonPayload);
    return profile;
};

const processIdToken = (id_token, otherInfo) => {
    if (!id_token) return;
    sessionStorage.removeItem('customerSegment');
    const profile = getProfileByToken(id_token);

    /*
        https://deopjira01.corp.hkjc.com/browse/PRJ1027-4820

        Priority Card Holder: 0 – N/A; Y – Has Priority Card; N – Has No Priority Card
        CBP Segment: 00000 – N/A, 00001 -00011 – Indicates the customer CBP segments
        Racing Participation: ‘Y’/’N’ Indicates whether customer has participation in racing
        Football Participation:	‘Y’/’N’ (Similar as above)
        M6 Participation: ‘Y’/’N’ (Similar as above)
        Priority Card Holder: 0 – N/A; Y – Has Priority Card; N – Has No Priority Card
        Member:	0 – N/A; 1 – Owners; 2 – Full Members; 3 – Racing Members; 4 – Racing Club
        Age group: 00 – N/A; 01 – under 18; 02 – 18-25;
                    03 – 26-30; 04 – 31-35; 05 – 36-40;
                    06 – 41-45; 07 – 46-50; 08 – 51-55;
                    09 – 56-60; 10 – 61-65; 11 – 66-70;
                    12 – 71-75; 13 – 76-80; 14 – above 80
        Gender:	0 – N/A; 1 – Male; 2 – Female
        Betting Account Indicator: ‘Y’/’N’ Indicate whether the customer has a betting account
        Language: Hardcoded as 0
        SpeedBet Customer: 0 – N/A; Y – SpeedBet customer; N – Non-SpeedBet customer
        Web TV Indicator: ‘Y’/’N’ Indicate whether the customer has access to WebTV.

        String character sequence
        =========================
        Character	Information
        0	Priority Card Holder
        1-5	CBP Segment
        6	Racing Participation
        7	Football Participation
        8	M6 Participation
        9	Member
        10-11	Age group
        12	Gender
        13	Betting Account Indicator
        14	Language
        15	SpeedBet Customer
        16	Football Web TV Indicator
        */

    var priority = getPriorityCardCode(otherInfo?.priorityCardTier);
    var cbp = '00000'; // GetDataStore('cbp_seg');
    if (cbp == '') cbp = '00000';
    else if (cbp.length < 5) cbp = new Array(6 - cbp.length).join('0') + cbp;
    var racing = profile.racingParticipation ? 'Y' : 'N';
    var football = profile.footballParticipation ? 'Y' : 'N';
    var marksix = profile.m6Participation ? 'Y' : 'N';
    var member = getMemberCode(otherInfo?.memberCategoryCode);
    var ageGroup = getAgeGroup(otherInfo?.age);
    var gender = getGenderCode(otherInfo?.gender);
    var bettingAC = profile.betAcct?.length > 0 ? 'Y' : 'N';
    var speedbet = profile.speedBet ? 'Y' : 'N';
    let webTV = profile.webTV ? 'Y' : 'N';
    let language = '0';

    const customerSegment =
        priority +
        cbp +
        racing +
        football +
        marksix +
        member +
        ageGroup +
        gender +
        bettingAC +
        language +
        speedbet +
        webTV;
    sessionStorage.setItem('customerSegment', customerSegment);
    if (profile.c_hash?.length > 0) {
        sessionStorage.setItem('customerHash', profile.c_hash);
    }
    sessionStorage.setItem('webTV', profile.webTV == true);
};

export const renewTokens = async (clientId = 'JCBW') => {
    const refreshToken = getSessionToken().refresh_token;
    if (refreshToken == null || refreshToken == '') {
        clearInterval(sessionStorage.getItem('tokenIntervalId'));
        //console.log('stop token renew ' + Date() + ' ' + sessionStorage.getItem('tokenIntervalId'));
    } else {
        const token = await renewAccessToken(refreshToken, clientId);
        if (token?.access_token) {
            await renewSSO(token.access_token);
        }
        return token;
    }
    return null;
};

export const useLoginLogout = (props) => {
    const { t } = useTranslation();
    const [EKBAAnswerReqData, setEKBAAnswerReqData] = useState(null);
    const [showLoginEKBAAnswer, setShowLoginEKBAAnswer] = useState(false);
    const [loginEKBAQuestion, setLoginEKBAQuestion] = useState('');
    const [loginIncorrectMsg, setLoginIncorrectMsg] = useState('');
    const [loginAccount, setLoginAccount] = useState('');
    const [password, setPassword] = useState('');
    const [accessToken, setAccessToken] = useState('');
    const [refreshToken, setRefreshToken] = useState('');
    const [accessTokenIdToken, setAccessTokenIdToken] = useState('');
    const [userInfo, setUserInfo] = useState({});
    const [loginTimeoutRemind, setLoginTimeoutRemind] = useState(null);
    const [isShowTandC, setIsShowTandC] = useState(false);
    const [isShowTODialog, setIsShowTODialog] = useState(false);
    const [isShowCSLogoutDialog, setIsShowCSLogoutDialog] = useState(false);
    const [showUserInfo, setShowUserInfo] = useState(false);
    const [showLastLoginInfo, setShowLastLoginInfo] = useState(false);
    const [showLogoutSucMsg, setShowLogoutSucMsg] = useState(false);
    const [showLogoutReminder, setShowLogoutReminder] = useState(false);
    const [showAnswerErrorMsg, setShowAnswerErrorMsg] = useState('');
    const [isShowLogoutBetText, setIsShowLogoutBetText] = useState(false);
    const [willOpenUrl, setWillOpenUrl] = useState('');
    const timeoutRef = useRef(0);
    const timerRef = useRef(null);
    const bgmPalyRef = useRef(null);

    // const { betslipMenu } = useContext(ConfigContext);
    // const { isSpeedbet, setIsSpeedbet, setBetBaskets, betBaskets } = useContext(MyBetSlipContext);
    const {config, isSpeedbet, setIsSpeedbet, setBetBaskets, setBalance, betBaskets} = props
    const { betslipMenu } = config;
    const [LoginAPILoading, setLoginAPILoading] = useState(false);

    useEffect(() => {
        const tokenObj = getSessionToken();
        if (tokenObj.access_token && tokenObj.refresh_token) {
            setAccessToken(tokenObj.access_token);
            setRefreshToken(tokenObj.refresh_token);
        }
    }, []);

    useEffect(() => {
        const fetchUserInfo = async () => {
            const userInfo = await getUserInfo(accessToken);
            userInfo && setUserInfo(userInfo);
            setUserInfoToSession(userInfo);
            const userInfoAdditional = await getUserInfoAdditional(
                accessToken,
                sessionStorage.getItem('login_webAccountLoginName')
            );

            processIdToken(accessTokenIdToken, userInfoAdditional);
            setUserInfoAdditionalToSession(userInfoAdditional);
            callWAReq();
            glassboxSetUserInfo();
        };
        const betslipEle = document.getElementById('root');
        if (accessToken && betslipMenu && accessTokenIdToken) {
            setShowLoginEKBAAnswer(false);
            setShowUserInfo(true);
            timeoutRef.current = 0;
            if (!userInfo || Object.keys(userInfo).length <= 0) {
                fetchUserInfo();
            }

            //use channel para, if invalid use BetslipConfig as default
            const sessionIdleTime =
                !isNaN(+sessionStorage.getItem('SessionIdleTime')) && sessionStorage.getItem('SessionIdleTime')
                    ? +sessionStorage.getItem('SessionIdleTime')
                    : betslipMenu.SessionIdleTime;
            const warningTimeout =
                !isNaN(+sessionStorage.getItem('WarningTimeout')) && sessionStorage.getItem('WarningTimeout')
                    ? +sessionStorage.getItem('WarningTimeout')
                    : betslipMenu.WarningTimeout;
            const warningIdleTime = sessionIdleTime - warningTimeout;

            timerRef.current = setInterval(() => {
                timeoutRef.current += 1;
                if (timeoutRef.current === warningIdleTime * 60) {
                    setLoginTimeoutRemind('1');
                    playBgmFunc();
                    bgmPalyRef.current = setInterval(()=>{
                    playBgmFunc();
                    }, 60 * 1000);
                }
                if (timeoutRef.current === (sessionIdleTime - 1) * 60) {
                    setLoginTimeoutRemind('2');
                }
                if (timeoutRef.current === sessionIdleTime * 60) {
                    clearInterval(timerRef.current);
                    timerRef.current = null;
                    clearInterval(bgmPalyRef.current);
                    bgmPalyRef.current = null;
                    setLoginTimeoutRemind('3');
                }
            }, 1000);
            betslipEle.addEventListener('click', resetLoginTimeout);
        } else {
            setShowLastLoginInfo(false);
            setShowUserInfo(false);
            setShowLogoutSucMsg(false);
            clearInterval(timerRef.current);
            timerRef.current = null;
            betslipEle.removeEventListener('click', resetLoginTimeout);
            bgmPalyRef.current !== null &&  clearInterval(bgmPalyRef.current);
            bgmPalyRef.current = null;
        }
        return () => {
            clearInterval(timerRef.current);
            timerRef.current = null;
            betslipEle.removeEventListener('click', resetLoginTimeout);
            bgmPalyRef.current !== null &&  clearInterval(bgmPalyRef.current);
            bgmPalyRef.current = null;
        };
    }, [accessToken, betslipMenu, accessTokenIdToken, userInfo]);

    useEffect(() => {
        if (loginTimeoutRemind) {
            if (['1', '2'].includes(loginTimeoutRemind)) {
                // const audio = document.getElementById('music1');
                // audio?.load();
                // audio?.play();
                setIsShowTODialog(true);
            } else {
                setIsShowTODialog(false);
            }
            setIsShowCSLogoutDialog((bool) => true);
        }
    }, [loginTimeoutRemind]);

    const playBgmFunc = () => {
        const audio = document.getElementById('music1');
        audio?.load();
        audio?.play();
    }

    const initLogin = async () => {
        loginStatus.isBusy = true;
        await logout(sessionStorage.getItem('access_token'));
        const initData = await initLoginAPI();
        if (initData.code === 415) {
            setLoginIncorrectMsg(415);
            loginStatus.isBusy = false;
            return;
        }
        if (initData?.authId) {
            return initData;
        }
    };

    const checkAccountPassword = async (account, password) => {
        loginStatus.isBusy = true;
        setShowLoginEKBAAnswer(false);
        setLoginIncorrectMsg('');
        setShowAnswerErrorMsg('');
        // has empty
        if (!account || !password) {
            setLoginIncorrectMsg('100001');
            loginStatus.isBusy = false;
            return;
        }

        const loginReqData = await initLogin();
        if (!loginReqData) return;

        const callback0 = loginReqData?.callbacks?.[0];
        callback0.input[0].value = account;
        const callback1 = loginReqData?.callbacks?.[1];
        callback1.input[0].value = password;
        const body = { ...loginReqData, callbacks: [callback0, callback1] };

        const data = await checkAccountPasswordAPI(body);
        // console.log('checkAccountPasswordAPI data', data);
        if (data) {
            // Incorrect Login Name or Betting Account No.(100004)
            if (data.code == 401) {
                const errData = JSON.parse(data.message);
                setLoginIncorrectMsg(errData.errorCode);
                loginStatus.isBusy = false;
                return;
            }
            // Timeout or Other error
            if ([415, 416].includes(data.code)) {
                setLoginIncorrectMsg(data.code);
                loginStatus.isBusy = false;
                return;
            }
            const authorize = await getAuthorize(data.tokenId, 'JCBW');
            if (authorize) {
                let code = '';
                if (authorize.url) {
                    const tempUrl = new URL(authorize.url);
                    const queryParams = new URLSearchParams(tempUrl.search);
                    code = queryParams.get('code');
                }

                if (code) {
                    const accessToken = await getAccessToken(code);
                    if (accessToken?.access_token) {
                        sessionStorage.setItem('access_token', accessToken.access_token);
                        /*
                        setAccessToken(accessToken.access_token);
                        setRefreshToken(accessToken.refresh_token);
                        setTokenToSession({
                            accessToken: accessToken.access_token,
                            refreshToken: accessToken.refresh_token,
                            login_account: loginAccount
                        });
                        if (accessToken?.id_token) {
                            setAccessTokenIdToken(accessToken.id_token);
                        }
                        */
                    }
                    if (accessToken) {
                        showEkbaQuestion(accessToken.access_token);
                    }
                }
            } else {
                setLoginIncorrectMsg('OTHER');
            }
        } else {
            setLoginIncorrectMsg(415);
            loginStatus.isBusy = false;
        }
    };

    const showEkbaQuestion = async (accessToken) => {
        const ekbaData = await initEkbaAPI(accessToken);
        // console.log('ekbaData', ekbaData);

        //return false if SSO expired
        if (
            ekbaData?.callbacks?.find((item) => {
                return item.type == 'PasswordCallback';
            })
        ) {
            loginStatus.isBusy = false;
            return false;
        }

        if (ekbaData?.callbacks) {
            const question = ekbaData?.callbacks[0].output[0]?.value;
            // Incorrect Password and others (100001, 100002, 100003)
            if (question.includes('errorCode')) {
                const errData = JSON.parse(question);
                setLoginIncorrectMsg(errData.errorCode);
                loginStatus.isBusy = false;
                return;
            }

            setShowLoginEKBAAnswer(true);
            setLoginEKBAQuestion(question);
            setEKBAAnswerReqData(ekbaData);
        } else {
            setLoginIncorrectMsg('OTHER');
        }
    };

    const checkEKBAAnswer = async (answer) => {
        loginStatus.isBusy = true;
        if (!EKBAAnswerReqData) {
            loginStatus.isBusy = false;
            return;
        }
        setShowAnswerErrorMsg('');
        const callbacks = EKBAAnswerReqData?.callbacks;
        const callback0 = callbacks?.[0];
        callback0.input[0].value = answer;
        const callback2 = callbacks?.[2];
        const code = callback2?.output[0]?.value;
        callback2.input[0].value = code;
        const body = { ...EKBAAnswerReqData, callbacks: [callback0, callbacks[1], callback2] };
        const data = await checkEKBAAnswerAPI(accessToken, body);
        if (data) {
            if (data.code && data.code == 401) {
                setLoginIncorrectMsg('100002');
                setShowLoginEKBAAnswer(false);
                setShowAnswerErrorMsg('');
                loginStatus.isBusy = false;
                return;
            }
            const outputValue = data?.callbacks?.[1]?.output[0]?.value;
            if (outputValue?.includes('errorCode')) {
                const outputData = JSON.parse(outputValue);
                if (['1', '2'].includes(outputData.failedAttempts)) {
                    setShowAnswerErrorMsg(outputData.failedAttempts);
                }
                return;
            }

            const authorize = await getAuthorize(data.tokenId);
            if (authorize) {
                let code = '';
                if (authorize.url) {
                    const tempUrl = new URL(authorize.url);

                    const queryParams = new URLSearchParams(tempUrl.search);
                    code = queryParams.get('code');
                }

                const accessToken = await getAccessToken(code);
                if (accessToken?.access_token) {
                    setAccessToken(accessToken.access_token);
                    setRefreshToken(accessToken.refresh_token);
                    setTokenToSession({
                        accessToken: accessToken.access_token,
                        refreshToken: accessToken.refresh_token,
                        idToken: accessToken.id_token,
                        login_account: loginAccount
                    });
                    if (accessToken?.id_token) {
                        setAccessTokenIdToken(accessToken.id_token);
                        setIsSpeedbetByIdToken(accessToken.id_token);
                    }
                    setIsShowTandC(true);
                    removeCurrentSessionRecords();
                    loginStatus.isBusy = false;
                    sessionStorage.setItem('is_logon', 1);

                    const refreshInverval = betslipMenu?.tokenRefreshInterval || 300;
                    const intervalId = setInterval(async () => {
                        const token = await renewTokens();
                        if (token?.access_token && token?.refresh_token) {
                            setAccessToken(token.access_token);
                            setRefreshToken(token.refresh_token);
                            setTokenToSession({
                                accessToken: token.access_token,
                                refreshToken: token.refresh_token,
                                idToken: token.id_token
                            });
                            //console.log('token renewed ' + Date() + ' ' + sessionStorage.getItem('tokenIntervalId'));
                        }
                    }, refreshInverval * 1000);
                    sessionStorage.setItem('tokenIntervalId', intervalId);
                }
            }
        } else {
            setLoginIncorrectMsg('OTHER');
        }
    };

    
    const checkNewCIAMJwt = async (jwt, setAPILoading) => {
        return null;
    };

    const setIsSpeedbetByIdToken = (idToken) => {
        const profile = getProfileByToken(idToken);
        if (profile?.speedBet) {
            setIsSpeedbet(profile.speedBet);
        }

        if (betBaskets.length != window.globalConfig.SPEEDBET_BASKET.length) {
            let newBaskets = Array(Number(window.globalConfig.SPEEDBET_BASKET.length)).fill([]);
            newBaskets[0] = betBaskets[0];
            setBetBaskets(newBaskets);
        }
    };

    const resetLoginTimeout = () => {
        timeoutRef.current = 0;
        setLoginTimeoutRemind('');
        setIsShowTODialog(false);
        bgmPalyRef.current !== null &&  clearInterval(bgmPalyRef.current);
        bgmPalyRef.current = null;
    };

    const closeLoginIncorrectMsg = () => {
        setLoginIncorrectMsg(null);
    };

    const logoutStateFn = () => {
        setLoginTimeoutRemind('');
        setAccessToken('');
        setRefreshToken('');
        removeToken();
        setUserInfo({});
        removeCurrentSessionRecords();
        setIsShowTODialog(false);
    };

    const isLogon =
        window.sessionStorage.getItem('access_token') != null && window.sessionStorage.getItem('access_token') != '';

    const isPCardUser =
        window.sessionStorage.getItem('login_priorityCardTier') != null &&
        window.sessionStorage.getItem('login_priorityCardTier') != '' &&
        window.sessionStorage.getItem('login_priorityCardTier') != 'NonPcard';

    return {
        checkAccountPassword,
        showLoginEKBAAnswer,
        setShowLoginEKBAAnswer,
        loginEKBAQuestion,
        loginIncorrectMsg,
        checkEKBAAnswer,
        loginAccount,
        setLoginAccount,
        password,
        setPassword,
        resetLoginTimeout,
        accessToken,
        logoutStateFn,
        setLoginTimeoutRemind,
        loginTimeoutRemind,
        isShowTODialog,
        setIsShowTODialog,
        isShowCSLogoutDialog,
        setIsShowCSLogoutDialog,
        isShowTandC,
        setIsShowTandC,
        userInfo,
        showUserInfo,
        setShowUserInfo,
        showLastLoginInfo,
        setShowLastLoginInfo,
        showAnswerErrorMsg,
        showLogoutSucMsg,
        setShowLogoutSucMsg,
        closeLoginIncorrectMsg,
        isShowLogoutBetText,
        setIsShowLogoutBetText,
        willOpenUrl,
        setWillOpenUrl,
        showLogoutReminder,
        setShowLogoutReminder,
        isLogon,
        isPCardUser,
        showEkbaQuestion,
        LoginAPILoading,
        setLoginAPILoading,
        checkNewCIAMJwt
    };
};
