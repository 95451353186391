import React, { useState, useRef, useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toLocaleLang } from '../../Common/home-common';
import { TandCText } from '../../BetSlip/LoginMenu';
import { useLoginLogout, SetLoginBusyStatus } from '../../BetSlip/LoginHooks';
import { LoginIncorrect } from '../../BetSlip/LoginBase';
import LoginEKBAAnswer from '../../BetSlip/LoginEKBAAnswer';
import LoginEKBAAnswerPopup from '../../BetSlip/LoginEKBAAnswerPopup';
import Header from '../../Header/Header';
import LangSelector from '../../Header/LangSelector';
import Footer from '../../Footer/Footer';
import Copyright from '../../Footer/Copyright';
import useClickAway from '../../Common/hooks/useClickAway';
import './index.scss';
import forgetpw from '../../../info/Include/images/forgetpw.svg';
import register from '../../../info/Include/images/register.svg';
import registerblue from '../../../info/Include/images/register-blue.svg';
import loginImg from '../../../info/Include/images/login-img-new.PNG';
import ewin from '../../../info/Include/images/eWin_new_logo_blue.svg';
import logo_ch from '../../../info/Include/images/logo_hkjc_ch.png';
import logo_en from '../../../info/Include/images/logo_hkjc_en.png';
import logo_ch_white from '../../../info/Include/images/logo_hkjc_ch_white.svg';
import logo_en_white from '../../../info/Include/images/logo_hkjc_en_white.svg';
import { timeoutPromise, useBreakpoint } from '../../Common/CommonHooks';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useBetSlipMsgQuery } from '../../Home/Data/SitecoreDataHooks';
import { GetOnlinePara } from '../../Common/ChannelParaFunc';
import { QueryConfig } from '../../Common/ConfigHelper';
import { useLoginLogout_IB, clearLoginSessionStorage, getWhitelistAcc } from '../../BetSlip/LoginIBHooks';
import { isEoD, getOldUrl } from '../../Common/GlobalFunc';
import { initSsoLogin, setSsoLogout } from '../../BetSlip/SsoHook';
import { useWindowSize, delay } from '../../Common/CommonHooks';
import oldVersionLogo from '../../../info/Include/images/betslip_logo.svg';
import { LoginOTP } from '../../BetSlip/LoginOTP';
import { TrustBrowser } from '../../BetSlip/TrustBrowser';
import ErrorPopup from '../../BetSlip/ErrorPopup';
import { WATrackerTrackClickEvent, callWAReq, getWebIDHashed } from '../../Common/utils/wa';
import LoginErrorPopup from '../../BetSlip/LoginErrorPopup';
const CustomScrollbars = (props) => (
    <Scrollbars
        autoHeight
        autoHeightMin={440}
        autoHeightMax={440}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        {...props}
    />
);

const Title = ({ isSpeedbet }) => {
    const { t } = useTranslation();
    return (
        <>
            {isSpeedbet ? (
                <>
                    <div className="title">{t('LB_LG_EPA')}</div>
                    <div className="title-hr" />
                </>
            ) : (
                <div className="ewin">
                    <img src={ewin} width={99} alt="" />
                </div>
            )}

            <div className="explanation">{t('LB_LG_EXP')}</div>
        </>
    );
};

const LoginInput = ({
    loginAccount,
    setLoginAccount,
    password,
    setPassword,
    checkAccountPassword,
    checkIsEod,
    APILoading,
    setLoginAPILoading,
    isReadOnly
}) => {
    const { t } = useTranslation();
    const usenameRef = useRef();
    const pwdRef = useRef();
    const { isMobileSize } = useWindowSize();

    return (
        <>
            <span className="login-t">{t('LB_LG_IN')}</span>
            <div className="input-c">
                <input
                    type="text"
                    id="login-account-input"
                    ref={usenameRef}
                    value={loginAccount}
                    onChange={(e) => {
                        let value = e.target.value;
                        if (isMobileSize && e.target.getAttribute('data-init') == 1) {
                            if (e.nativeEvent.data != null) {
                                value = e.nativeEvent.data;
                            }
                            e.target.setAttribute('data-init', 0);
                        }
                        setLoginAccount(value);
                    }}
                    onFocus={(e) => {
                        if (isMobileSize) {
                            e.preventDefault();
                            e.target.setAttribute('data-init', 1);
                            e.target.setSelectionRange(10, 10);
                            setTimeout(function () {
                                e.target.setSelectionRange(10, 10);
                            });
                        }
                        usenameRef.current.setAttribute('placeholder', '');
                    }}
                    onBlur={() => {
                        usenameRef.current.setAttribute('placeholder', t('LB_BS_LOGIN_ACCOUNT_PLACEHOLDER'));
                    }}
                    placeholder={t('LB_BS_LOGIN_ACCOUNT_PLACEHOLDER')}
                    maxLength={15}
                    className="login-input usename"
                    disabled={APILoading || isReadOnly}
                    readOnly={isReadOnly}
                />
            </div>
            <div className="input-c">
                <input
                    ref={pwdRef}
                    id="login-password-input"
                    type="password"
                    value={password}
                    onChange={(e) => {
                        let value = e.target.value;
                        if (isMobileSize && e.target.getAttribute('data-init') == 1) {
                            if (e.nativeEvent.data != null) {
                                value = e.nativeEvent.data;
                            }
                            e.target.setAttribute('data-init', 0);
                        }
                        setPassword(value);
                    }}
                    onFocus={(e) => {
                        if (isMobileSize) {
                            e.preventDefault();
                            e.target.setAttribute('data-init', 1);
                            e.target.setSelectionRange(10, 10);
                            setTimeout(function () {
                                e.target.setSelectionRange(10, 10);
                            });
                        }
                        pwdRef.current.setAttribute('placeholder', '');
                    }}
                    onBlur={(e) => {
                        pwdRef.current.setAttribute('placeholder', t('LB_BS_LOGIN_PASSWORD_PLACEHOLDER'));
                    }}
                    placeholder={t('LB_BS_LOGIN_PASSWORD_PLACEHOLDER')}
                    maxLength={20}
                    disabled={APILoading || isReadOnly}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            checkAccountPassword(loginAccount, password);
                        }
                    }}
                    className="login-input password"
                    readOnly={isReadOnly}
                />
            </div>
            <button
                className={`button login-button cta_yellow ${APILoading ? 'disabled' : ''}`}
                onClick={() => {
                    !APILoading && checkAccountPassword(loginAccount, password);
                }}
            >
                {t('LB_SIGNIN')}
            </button>
        </>
    );
};

const Link = () => {
    const { t, i18n } = useTranslation();
    const lang = toLocaleLang(i18n.language);
    const haveAcc = `${window.globalConfig.WCIP_URL}/registerbettingservice.aspx?UIC=${lang}&EP=BS`;
    const noAcc = `${window.globalConfig.SP_URL}/infomenu/${i18n.language}/register/applybet.asp`;
    const [show, setShow] = useState(false);
    const item1Ref = useRef(null);
    const close = useCallback(() => {
        setShow(false);
    }, []);
    useClickAway(item1Ref, close);

    return (
        <div className="link">
            <div
                ref={item1Ref}
                className={`item item1 cursor-pointer ${show ? 'show' : ''}`}
                onClick={() => setShow(!show)}
            >
                <img src={show ? registerblue : register} alt="" />
                <span className="pre">{t('LB_BS_REGISTER1')}</span>
                {show && (
                    <div className="tooltips">
                        <div
                            onClick={() => {
                                window.location.href = haveAcc;
                            }}
                            dangerouslySetInnerHTML={{ __html: t('LB_LG_HAVE_ACC') }}
                        />
                        <div
                            onClick={() => {
                                window.location.href = noAcc;
                            }}
                            dangerouslySetInnerHTML={{ __html: t('LB_LG_NO_ACC') }}
                        />
                    </div>
                )}
            </div>
            <div className="item item2">
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        WATrackerTrackClickEvent('eWin_Cannot-login');
                        window.location.href = t('LB_BS_LOGIN_ACCESS_LOCKED_URL');
                    }}
                >
                    <img src={forgetpw} alt="" />
                    <span>{t('LB_BS_CANNOT_LOGIN')}</span>
                </div>
            </div>
        </div>
    );
};

const AnswerModal = (props) => {
    const { show, onHide } = props;
    const { t } = useTranslation();
    return (
        <Modal
            backdrop="static"
            show={show}
            onHide={onHide}
            size="lg"
            dialogClassName="custom-modal answer-modal"
            aria-labelledby="answer-modal"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="answer-modal">{t('LB_BS_LOGIN_QUESTION')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <LoginEKBAAnswer {...props} />
            </Modal.Body>
        </Modal>
    );
};

const EWalletModal = (props) => {
    const { show, onHide, setPassword, setLoginAccount } = props;
    const { t } = useTranslation();

    return (
        <Modal
            backdrop="static"
            show={show}
            onHide={onHide}
            size="lg"
            dialogClassName="custom-modal answer-modal"
            aria-labelledby="answer-modal"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('LB_BS_EWALLET_TITLE')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="login-answer-container">
                    <div className="login-answer-content">
                        <div className="forgot-answer">{t('LB_BS_EWALLET_DESC')}</div>
                        <div className="button-box">
                            <div
                                className="bsBgColor cta_brand"
                                onClick={() => {
                                    setLoginAccount('');
                                    setPassword('');
                                    onHide();
                                }}
                            >
                                {t('LB_BS_EWALLET_LATER')}
                            </div>
                            <div
                                className="cta_yellow ewallet_upgrade"
                                onClick={() => {
                                    setLoginAccount('');
                                    setPassword('');
                                    onHide();
                                    window.open(t('LB_BS_EWALLET_UPGRADE_URL'), '_blank');
                                }}
                            >
                                {t('LB_BS_EWALLET_UPGRADE')}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

const TCModal = (props) => {
    const { onHide } = props;
    const { t } = useTranslation();
    const { msgLoading, getBetSlipLoginTnC } = useBetSlipMsgQuery();

    let textData = '';
    if (!msgLoading) {
        textData = getBetSlipLoginTnC();
        if (textData === '') {
            onHide();
        }
    }

    return !msgLoading && textData !== '' ? (
        <Modal
            backdrop="static"
            {...props}
            size="lg"
            dialogClassName="custom-modal tc-modal"
            aria-labelledby="tc-modal"
            centered
        >
            <Modal.Header>
                <Modal.Title id="tc-modal">{t('LB_BS_LOGIN_TANDC')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CustomScrollbars>
                    <div className="tc-content">
                        <TandCText textData={textData} />
                    </div>
                </CustomScrollbars>
                <div className="button-c">
                    <button
                        className="button proceed-button"
                        onClick={() => {
                            onHide();
                        }}
                    >
                        {t('LB_BS_LOGIN_TANDCBTN')}
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    ) : null;
};

const IncorrectModal = (props) => {
    const { loginIncorrectMsg, onHide,setShowLoginLockPopup } = props;
    const { t } = useTranslation();

    useEffect(()=>{
        if(loginIncorrectMsg=='CIAM_100002'){
            onHide()
            setShowLoginLockPopup(true)
        }
    },[loginIncorrectMsg])
    return (
        <>
            {loginIncorrectMsg!=='CIAM_100002'&&
            <Modal
                backdrop="static"
                show={loginIncorrectMsg}
                onHide={onHide}
                dialogClassName="custom-modal incorrect-modal"
                aria-labelledby="incorrect-modal"
                centered
                style={{ zIndex: 100001 }}
                backdropClassName='incorrect-modal-backdrop'
            >
                <Modal.Body>
                    <div className="incorrect-content">
                        {loginIncorrectMsg && (
                            <LoginIncorrect messageCode={loginIncorrectMsg} onClose={onHide} showClose={false} />
                        )}
                    </div>
                    <div className="button-c cancel-button-c">
                        <button
                            className="button cancel-button"
                            onClick={() => {
                                onHide();
                            }}
                        >
                            {t('LB_CLOSE')}
                        </button>
                    </div>
                </Modal.Body>
            </Modal>
            }
        </>
        
        
    );
};

const Ewin2Header = () => {
    const { t, i18n } = useTranslation();
    const breakpoint = useBreakpoint();
    return breakpoint === 'mobile' ? (
        <div className="Ewin2Header-m">
            <img src={i18n.language === 'en' ? logo_en_white : logo_ch_white} alt="" />
            <LangSelector /> 
        </div>
    ) : (
        <>
            <div className="Ewin2Header">
                <a
                    className={`old-version ${
                        !window.globalConfig.ENABLE_OLD_SITE_LINK ? 'vsbHide' : ''
                    }`}
                    href={getOldUrl(i18n.language)}
                >
                    <img src={oldVersionLogo} />
                    {t('LB_OLD_VERSION')}
                </a>
                <LangSelector /> 
            </div>
            <div className="logo-banner">
                <img src={i18n.language === 'en' ? logo_en : logo_ch} alt="" />
            </div>
        </>
    );
};

const Ewin2Footer = () => {
    return (
        <div className="Ewin2Footer">
            <Copyright />
        </div>
    );
};

const Login = ({ type = 'Speedbet', loginLogout, loginLogoutFR, login_shared_states }) => {
    const isSpeedbet = type === 'Speedbet';
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    // const loginLogout = window.globalConfig.IS_IB ? useLoginLogout_IB() : useLoginLogout();
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [otp, setOtp] = useState('');
    const { isMobileSize } = useWindowSize();
    const [isShowNewEKBA, setIsShowNewEKBA] = useState(false);
    const [isOnWhitelist,setIsOnWhiteList] = useState(false)
    const {
        checkAccountPassword,
        showLoginEKBAAnswer,
        setShowLoginEKBAAnswer,
        loginEKBAQuestion,
        // loginIncorrectMsg,
        checkEKBAAnswer,
        loginAccount,
        setLoginAccount,
        // password,
        // setPassword,
        // resetLoginTimeout,
        accessToken,
        // logoutStateFn,
        // loginTimeoutRemind,
        // isShowTODialog,
        // setIsShowTODialog,
        // isShowCSLogoutDialog,
        setIsShowCSLogoutDialog,
        isShowTandC,
        setIsShowTandC,
        // userInfo,
        // showUserInfo,
        setShowUserInfo,
        // showLastLoginInfo,
        // setShowLastLoginInfo,
        showAnswerErrorMsg,
        // showLogoutSucMsg,
        // setShowLogoutSucMsg,
        // closeLoginIncorrectMsg,
        // isShowLogoutBetText,
        // setIsShowLogoutBetText,
        // willOpenUrl,
        // setWillOpenUrl,
        showLogoutReminder,
        // setShowLogoutReminder,
        LoginAPILoading,
        setLoginAPILoading,
        showEkbaQuestion,
        showEwalletDialog,
        setShowEwalletDialog,
    } = loginLogout;

    const {
        FR_loginWithUsernamePassword,
        FR_loginWithOTP,
        FR_initStep,
        FR_TurstBrowser,
        FR_GetToken,
        FR_Logout,
        isShowOTPAuth,
        setIsShowOTPAuth,
        invalidOTP, 
        setInvalidOTP,
        isShowEKBAQuestion,
        setIsShowTrustBrowser,
        isShowTrustBrowser,
        FR_GetMobile,
        FR_ResendOTP,
        showErrorPopup,
        setShowErrorPopup,
        setLoginIncorrectMsg,
        showLoginLockPopup,
        setShowLoginLockPopup,
        isShowOTPToast,
        setIsShowOTPToast,
        FR_NewEKBA
    } = loginLogoutFR;
    
    const {
        password,
        setPassword,
        loginIncorrectMsg,
        closeLoginIncorrectMsg
    } = login_shared_states;

    useEffect(() => {
        // re-init for OTP overlay
        setOtp('');
        setInvalidOTP(false);
    }, [isShowOTPAuth]);

    const ssoInit = async () => {
        //await FR_initStep();
        const data = await initSsoLogin();
        if (data != false) {
            setLoginAccount(data);
            setPassword('********');
            setIsReadOnly(true);
            setShowUserInfo(true);
        } else {
            setIsReadOnly(false);
        }
    };

    useEffect(() => {
        setIsShowCSLogoutDialog(false)
        !sessionStorage.getItem('setShowLogoutReminder') && ssoInit();
        sessionStorage.removeItem('setShowLogoutReminder');
        //FR_Logout().then(FR_initStep);
    }, []);

    const onClickCheckOTP = async () => {
        // loading
        // pop success or error message

        // reset state with async await
        setInvalidOTP("");  
        await delay(1);
        await FR_loginWithOTP(otp);
    }

    const resetMyBetSlipLoginInfo = (obj) => {};

    const handleClickLoginEKBAAnswerCancel = () => {
        setShowLoginEKBAAnswer(false);
        SetLoginBusyStatus(false);
    };

    const handleClickLogout = () => {
        setShowLoginEKBAAnswer(false);
        SetLoginBusyStatus(false);
        ssoExpired();
        setIsReadOnly(false);
    };

    // otp overlay click Verify by EKBA
    const onClickEKBA = async () => {
        WATrackerTrackClickEvent('eWin_CIAM_S1a-OTP-clickeKBA_whitelistWithMobile');
        setIsShowOTPAuth(false);
        setLoginAPILoading(true);
        setIsShowNewEKBA(true);
        WATrackerTrackClickEvent('eWin_CIAM_S1b-eKBA_whitelistWithMobile');
        await checkAccountPassword(loginAccount, password, true);
        setLoginAPILoading(false);
    };

    // otp overlay click Cancel
    const onClickCancelOTP = () => {
        setLoginAccount('');
        setPassword('');
        setIsShowOTPAuth(false);
    };

    const onClickCancelErrorPopup = () => {
        setLoginAccount('');
        setPassword('');
        setIsShowOTPAuth(false);
        setShowErrorPopup(false);
        FR_initStep();
    };
    const onClickCancelLoginLockPopup = () => {
        setLoginAccount('');
        setPassword('');
        setIsShowOTPAuth(false);
        setShowLoginLockPopup(false);
        FR_initStep();
    };

    const handleCheckPassword = async () => {
        setLoginAPILoading(true);
        setIsShowNewEKBA(false);
        try {
            let isEod = await Promise.race([checkIsEod(), timeoutPromise()]);
            // const isEod = await checkIsEod();
            if (!isEod) {
                console.debug('handleClickSignIn', sessionStorage.getItem('sso_web_id')?.length, window.globalConfig.NEW_CIAM)
                if (window.globalConfig.NEW_CIAM) {
                    await loginWithNewCIAM(loginAccount, password);
                } else if (sessionStorage.getItem('sso_web_id')?.length > 0 && (await showEkbaQuestion(true)) == false) {
                    ssoExpired();
                } else {
                    await checkAccountPassword(loginAccount, password);
                }
            }
        } catch (err) {
            console.log(err);
            if (err.message === 'Request timed out') {
                setLoginIncorrectMsg('TIMEOUT');
            } else {
                setLoginIncorrectMsg('OTHER');
            }
        }           
        setLoginAPILoading(false);
    };

    
    const loginWithNewCIAM = async (loginAccount, password) => {
        window.sessionStorage.setItem("LoginWA", "eWin_login");
        const isOnWhitelist = await getWhitelistAcc(loginAccount)
        setIsOnWhiteList(isOnWhitelist)
        // let isLoginSussess = false;
        //if (isOnWhitelist === 'TIMEOUT') {
        //    setLoginIncorrectMsg('TIMEOUT');
        //    return;
        //}
        if(isOnWhitelist){
            window.sessionStorage.setItem("LoginWA", sessionStorage.getItem("LoginWA") + "_whitelist");
            await FR_loginWithUsernamePassword(loginAccount, password);    
        } else {
            window.sessionStorage.setItem("LoginWA", sessionStorage.getItem("LoginWA") + "_non-whitelist");
            await checkAccountPassword(loginAccount, password);
        }
    }

    const isSsoLogin = sessionStorage.getItem('sso_web_id')?.length > 0;

    const ssoExpired = (clearSSO = true) => {
        if (clearSSO) {
            setIsReadOnly(false);
            setPassword('');
            setLoginAccount('');
            setSsoLogout();
        } else {
            ssoInit();
        }

        clearLoginSessionStorage();
    };

    const handleClickLoginEKBAAnswerConfirm = async (answer) => {
        setLoginAPILoading(true);
        try {
            let isEod = await Promise.race([checkIsEod(), timeoutPromise()]);
            if (!isEod) {
                let isCorrect = await checkEKBAAnswer(answer, setLoginAPILoading,isOnWhitelist);
                if(isOnWhitelist&&isCorrect==true){
                    FR_NewEKBA();
                }
                resetMyBetSlipLoginInfo();
                !window.globalConfig.IS_IB && setLoginAPILoading(false);
            } else {
                setLoginAPILoading(false);
            }
        } catch (err) {
            console.log(err);
            if (err.message === 'Request timed out') {
                setLoginIncorrectMsg('TIMEOUT');
            } else {
                setLoginIncorrectMsg('OTHER');
            }
            setLoginAPILoading(false);
        } 
    };

    const closeCandTFn = () => {
        setIsShowTandC(false);
        if (accessToken) {
            setShowUserInfo(true);
            if(window.history.state.idx > 0) {
                navigate(-1);
            } else {
                navigate(`/${i18n.language}/racing`);
            }

        }
    };

    const closeEWallet = () => {
        setPassword('');
        setLoginAccount('');
        setShowEwalletDialog(false);
    };

    useEffect(() => {
        checkIsEodAsync();
    }, [sessionStorage.getItem('OOSMsgId')]);

    const checkIsEodAsync = async () => {
        await QueryConfig('channelParas', window.globalConfig.CHANNEL_PARA_URL);
        return setEodUi();
    };

    const checkIsEod = async () => {
        await QueryConfig('channelParas', window.globalConfig.CHANNEL_PARA_URL);
        return setEodUi();
    };

    const setEodUi = () => {
        if (isEoD()) {
            handleClickLoginEKBAAnswerCancel();
            return true;
        } else {
            return false;
        }
    };

    const getEodMessage = () => {
        const { t } = useTranslation();
        let message = GetOnlinePara(i18n.language === 'en' ? 'OOSEngContent' : 'OOSChiContent');
        if (message == null || message.length <= 0) {
            message = t('LB_BS_EOD');
        }
        return message;
    };

    const onClickTrustBrowser = async (trust) => {
        await FR_TurstBrowser(trust);
    }

    return (
        <div className={`GL login-page ${isSpeedbet ? 'isSpeedbet' : 'eWin2'}`}>
            <div id="eod">
                <div className={`eod ${isEoD() ? 'show' : ''}`}>
                    <div className="eod-msg">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: getEodMessage()
                            }}
                        ></div>
                    </div>
                </div>
            </div>
            {isSpeedbet ? <Header isSpeedbet={isSpeedbet} loginLogoutHook={loginLogout} /> : <Ewin2Header />}
            <div className="login-body">
                <Title isSpeedbet={isSpeedbet} />
                <div className="login-c">
                    {isSpeedbet ? (
                        <div className="image">
                            <img src={loginImg} alt="" />
                        </div>
                    ) : null}
                    <div className="login">
                        <LoginInput
                            loginAccount={loginAccount}
                            setLoginAccount={setLoginAccount}
                            password={password}
                            setPassword={setPassword}
                            checkAccountPassword={handleCheckPassword}
                            checkIsEod={checkIsEod}
                            APILoading={LoginAPILoading}
                            setLoginAPILoading={setLoginAPILoading}
                            isReadOnly={isReadOnly}
                        />
                        {<IncorrectModal
                            loginIncorrectMsg={loginIncorrectMsg}
                            onHide={closeLoginIncorrectMsg}
                            showClose={false}
                            setShowLoginLockPopup = {setShowLoginLockPopup}
                        />}
                    </div>
                </div>
                <Link />
                {showLoginEKBAAnswer && !isShowNewEKBA && (
                    <AnswerModal
                        show={showLoginEKBAAnswer}
                        loginEKBAQuestion={loginEKBAQuestion}
                        showAnswerErrorMsg={showAnswerErrorMsg}
                        onHide={handleClickLoginEKBAAnswerCancel}
                        onClickCancel={handleClickLoginEKBAAnswerCancel}
                        onClickConfirm={handleClickLoginEKBAAnswerConfirm}
                        onClickLogout={handleClickLogout}
                        APILoading={LoginAPILoading}
                        isSsoLogin={isSsoLogin}
                    />
                )}
                {showLoginEKBAAnswer && isShowNewEKBA && (
                    <LoginEKBAAnswerPopup
                        loginEKBAQuestion={loginEKBAQuestion}
                        showAnswerErrorMsg={showAnswerErrorMsg}
                        onClickCancel={handleClickLoginEKBAAnswerCancel}
                        onClickConfirm={handleClickLoginEKBAAnswerConfirm}
                        onClickLogout={handleClickLogout}
                        isSsoLogin={isReadOnly}
                        APILoading={LoginAPILoading}
                        closeBtnEnabled={false}
                        setShowLoginLockPopup = {setShowLoginLockPopup}
                    />
                )}
                <TCModal show={isShowTandC} onHide={closeCandTFn} />
                <EWalletModal
                    show={showEwalletDialog}
                    onHide={closeEWallet}
                    setPassword={setPassword}
                    setLoginAccount={setLoginAccount}
                />
                {isShowOTPAuth && (
                    <LoginOTP
                        key={isShowOTPAuth.toString()} // Add a unique key to force re-render
                        showMsg={isShowOTPAuth}
                        onClickNext={onClickCheckOTP}
                        invalidOTP={invalidOTP}
                        isMobile={isMobileSize}
                        otp={otp}
                        setOtp={setOtp}
                        setIsShowOTPAuth={setIsShowOTPAuth}
                        onClickEKBA={onClickEKBA}
                        onClickCancelOTP={onClickCancelOTP}
                        closeBtnEnabled={true}
                        getMobile={FR_GetMobile}
                        resendOTP={FR_ResendOTP}
                        isShowOTPToast={isShowOTPToast}
                        setIsShowOTPToast = {setIsShowOTPToast}
                        setShowLoginLockPopup = {setShowLoginLockPopup}
                    />
                )}
                {isShowTrustBrowser && (
                    <TrustBrowser 
                        showMsg={true} 
                        FR_TurstBrowser={onClickTrustBrowser} 
                        isShowTrustBrowser={isShowTrustBrowser} 
                        setIsShowTrustBrowser={setIsShowTrustBrowser} 
                    />
                )}
                {showErrorPopup && (
                    <ErrorPopup
                        errorType={showErrorPopup}
                        handleClickCancel={onClickCancelErrorPopup}
                        setShowErrorPopup={setShowErrorPopup}
                    />
                )}
                {showLoginLockPopup && (
                    <LoginErrorPopup
                        // errorType={showErrorPopup}
                        handleClickCancel={onClickCancelLoginLockPopup}
                        setShowErrorPopup={setShowLoginLockPopup}
                        closeLoginIncorrectMsg = {closeLoginIncorrectMsg}
                    />
                )}
            </div>
            {/* {isSpeedbet ? <Footer /> : <Ewin2Footer />} */}
            {<Footer />}
        </div>
    );
};

export default Login;
