export const disableBgScroll = () => {
    const scrollY = window.scrollY; // 记录当前滚动位置
    document.body.style.position = 'fixed';
    document.body.style.overflow = "hidden";
    document.body.style.top = `-${scrollY}px`; // 将页面固定在当前滚动位置
}

export const enableBgScroll = () => {
    const scrollY = document.body.style.top; // 获取之前的顶部偏移
    document.body.style.position = 'relative';
    document.body.style.overflow = null;
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1); // 恢复滚动位置
}