import React, { useCallback, useEffect, useMemo, useState, useRef, useContext } from 'react';
import { GlobalContext } from '../../contexts/GlobalContext';


export function isMobileSize() {
    return ( window.matchMedia("(max-width: 1023px)").matches && window.matchMedia("(pointer:coarse)").matches ) && sessionStorage.getItem('isDeskTopVersion') !=='1'
}

export function getScreenInfo() {
    return {
        width: window.innerWidth,
        height: window.innerHeight,
        isMobileSize: isMobileSize()
    };
}

export const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
        isMobileSize: isMobileSize()
    });
    const timer = useRef(null);
    useEffect(() => {
        function handleResize() {
            timer.current !== null && clearTimeout(timer.current);
            timer.current = setTimeout(() => {
                setWindowSize({ ...getScreenInfo() });
            }, 250);
        }
        // Add event listener
        window.addEventListener('resize', handleResize);
        // Call handler right away so state gets updated with initial window size
        setWindowSize({ ...getScreenInfo() });
        // Remove event listener on cleanup
        return () => {
            window.removeEventListener('resize', handleResize);
            timer.current !== null && clearTimeout(timer.current);
        }
    }, []);

    return windowSize;
};

function isiPad() {
    var isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    var isMacLike = navigator.userAgent.includes('Intel Mac OS X');
  
    return isTouchDevice && isMacLike;
}

export const getDeviceType = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const platform = navigator.platform.toLowerCase();
  
    const isAndroid = /android/.test(userAgent) || /android|arm|aarch64/.test(platform);
    const isIOS = /iphone|ipad|ipod/.test(userAgent) || /iphone|ipad|ipod|macintel/.test(platform);
    const isTablet = /ipad|android(?!.*mobile)|tablet/.test(userAgent) || /ipad|tablet/.test(platform) || isiPad()
                        || isAndroid && (window.screen.width > 738 && window.screen.height > 738);
    const isMobile = /iphone|ipod|android.*mobile/.test(userAgent) || /iphone|ipod|android/.test(platform);
    const isDesktop = !isMobile && !isTablet;
    
    if (window.globalConfig.deviceType) return window.globalConfig.deviceType; // testing
    // return {
    //     "isDesktop": false,
    //     "isMobile": false,
    //     "isTablet": true,
    //     "isAndroid": false,
    //     "isIOS": false,
    //   };

    return {
      isDesktop,
      isMobile,
      isTablet,
      isAndroid,
      isIOS
    };
  }

export const delay = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export const timeoutPromise = () => {
    return new Promise((_, reject) => {
        console.log('window.globalConfig.LOGIN_TIMEOUT_DURATION', window.globalConfig.LOGIN_TIMEOUT_DURATION)
        setTimeout(() => {
            console.log('timeout reject')
            reject(new Error('Request timed out'));
        }, window.globalConfig.LOGIN_TIMEOUT_DURATION);
    });
}


const defalutBreakpoints = { Desktop: 1920, size1440: 1440, size1279: 1279, mobile: 768 };
export const useBreakpoint = (breakpoints = defalutBreakpoints) => {
    const sortedBreakpoints = useMemo(() => {
        return Object.entries(breakpoints).sort((a, b) => (a[1] >= b[1] ? -1 : 1));
    }, [breakpoints]);
    const calcBreakpoint = useCallback(() => {
        const point = sortedBreakpoints.reduce((acc, [name, width]) => {
            if (width >= window.innerWidth) {
                return name;
            } else {
                return acc;
            }
        }, sortedBreakpoints[0][0]);
        // console.log(point)
        return point;
    }, [sortedBreakpoints]);
    const [point, setPoint] = useState(() => {
        return calcBreakpoint();
    });
    useEffect(() => {
        const handlePoint = () => {
            setPoint(calcBreakpoint());
        };
        window.addEventListener('resize', handlePoint);
        return () => {
            window.removeEventListener('resize', handlePoint);
        };
    }, [calcBreakpoint]);
    return point;
};


export const useUpdateRNotes = () => {
    const [updateRNotes, setUpdateRNotes] = useState("");
    const timer = useRef(null);
    useEffect(() => {
        timer.current = setInterval(() => {
            if ( updateRNotes != window.updateRNotes ) {
                setUpdateRNotes(window.updateRNotes);
            }
        }, 1000);

        // Remove event listener on cleanup
        return () => {
            timer.current !== null && clearInterval(timer.current);
        }
    }, []);

    return updateRNotes;
};

export const useShowAlertDialog = () =>{
    const { isMobileSize } = useWindowSize();
    const {setMobileAlertObj} = useContext(GlobalContext);

    const getAlertShowMode = (message) => {
        if(isMobileSize){
            setMobileAlertObj({
                isShow: true,
                alertMsg: message,
            })
        }else{
            alert(message);
        }
    }

    return {
        getAlertShowMode,
    }
}