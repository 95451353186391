import React, { useContext, useCallback, useState, Fragment, useRef } from 'react';
import { onClickCheckBox } from './AddBet';
import { useTranslation } from 'react-i18next';
import { FootballContext } from '../../Home/FootballPage';
import { GlobalContext } from '../../../contexts/GlobalContext';
import { NewWindow } from '../../Common/home-common';
import { InplayClock, isExtraTimeMatch, TournImage, Venue, TVChannel, isMatchCompletedStatus, getETBetType } from './MatchBase';
import { toDateFormat, formatOdds, isSellingStarted, isMatchResultPage} from './Common';
import {
    isMatchKickOff,
    getRunningResult,
    getHandicap,
    getGoalLine,
    getFoPool,
    getCombination,
    goToAllOddsPage,
    LiveInfo,
    getOddsArrowCss
} from './MatchBase';
import CRSTable, { toCRSLabel, getFirstNCombStr, isCRSOtherSels } from './CRSTable';
import { CRSTopTips } from './CRSTopTips';
import FGSTable from './FGSTable';
import SGATable from './SGATable';
import SPCTable from './SPCTable';
import NthColsTable from './NthColsTable';
import NTSLastOddsTable from './NTSLastOddsTable';
import CheckBox from '../../Common/ui-components/CheckBox';
import ArrowIcon from '../../Common/ui-components/ArrowIcon';
import { Cookies } from '../../Common/CookieHelper';

const Row = ({ data, betType, etBetType, expandState, handleSetLineState, isCloseLine }) => {

    const context = useContext(FootballContext);
    const globalContext = useContext(GlobalContext);
    const { t, i18n } = useTranslation();
    const [checkboxState, setCheckboxState] = useState([]);
    const foPool = getFoPool(data, betType, etBetType);
    const foPoolRef = useRef()
    foPoolRef.current = foPool
    const hdcPool = context.content.config.HandicapOnlyPools.includes(betType);
    const isMultipleLineOdds = context.content.config.GoalLinePools.includes(foPool?.oddsType);
    const isResultPage = isMatchResultPage(context.content.page);
    const isFGSPool = foPool?.oddsType=="FGS";
    const [crsTipsObj, setCrsTipsObj] = useState({
        isCrsTipsShow: false,
        clickType: 'label',
        obj: {},
    });

    const clickHandlerFunc = (type,id,obj) => {
        const [betType, lineId, combId, poolId, comb] = id.split('_')
        const crsPool = context.content.config.CorrectScorePools.includes(betType);
        const crsOhterComb = isCRSOtherSels(comb) && comb!="DO";
        const isCrsAndOtherComb= window.fbConfig.showCRSToolTips && crsPool && crsOhterComb;
        if(isCrsAndOtherComb){
            if(Cookies.getData('isReadCrsTips')){
                typeHandlerFunc(type,obj)
            }else{
                setCrsTipsObj({
                    isCrsTipsShow: true,
                    clickType: type,
                    obj,
                })
            }
        }else{
            typeHandlerFunc(type,obj)
        }
    }
    const crsTipsCbFunc = (para) => {
        Cookies.setData('isReadCrsTips',1)
        setCrsTipsObj({
            isCrsTipsShow: false,
            clickType: para.clickType,
            obj: {},
        })
        typeHandlerFunc(para.clickType,para.obj)
    }
    const typeHandlerFunc = (type,obj) =>{
        const {allupData,curState, isSelling, setCheckboxState}=obj;
        if(type=='box'){
            onClickCheckBox(curState, !isSelling, context, setCheckboxState)
        }else{
            context.state.onClickOddsValue(allupData)
        }
    }
    const resetCrsTipsObjFunc = () => {
        setCrsTipsObj({
            isCrsTipsShow: false,
            clickType: 'label',
            obj: {},
        })
    }
    const crsTipsPorops={
        crsTipsObj,
        crsTipsCbFunc,
        resetCrsTipsObjFunc,
    }

    const getOddsVal = useCallback((line, combStr, isMobile, lineNum, showCombStr, hasO) => {
        let foPool =  foPoolRef.current
        let comb = null;
        if ( isCRSOtherSels(combStr) ) {
            if(['HO', 'AO', 'DO'].includes(combStr)){
                comb = line?.combinations.filter(x => x.str == combStr)[0];
            }else{
                comb = line?.combinations.filter(x => x.selections?.[0]?.str == combStr)[0];
            }
            
        }
        else {
            comb = line?.combinations.filter(x => x.str && x.str == combStr)[0];
        }
        if (comb == null)
            return null;

        let isKickOff = isMatchKickOff(data.status);
        let isSelling = !isResultPage && isSellingStarted(data, foPool, line) && comb.status == 'AVAILABLE';
        let allowAddToAllUpCal = isSelling || (!isKickOff && comb.status == 'AVAILABLE');
        const isUnClickable= !isResultPage && !isSelling && isKickOff;
        let oddsVal = isUnClickable ? '---' : formatOdds(t, comb.currentOdds, isResultPage ? '' : comb.status);
        if ( isFGSPool && comb.status.indexOf("REFUND")>=0 && context.content.page != 'RESULTS') {
            oddsVal = t('LB_FB_RFD');
        }
        let lineId = line.lineId;
        let combId = comb.combId;

        let id = foPool?.oddsType + '_' + lineId + "_" + combId + "_" + foPool.id + "_" + combStr;

        let curState = context.content.oddsSelection.filter(x => x.id == id)[0];

        const getMobileOddsItem = (oddsVal, curState, isStopSelling, allupData) => {
            const arrowCss = getOddsArrowCss(curState, isNaN(Number(oddsVal)));
            return <>
                <div className={`oddsValueM ${arrowCss}`}>
                    <div className={`oddsArrowM ${arrowCss}`}></div>
                    <span title={t('LB_ALLUP_CALCULATOR') + 1} className={`add-to-slip oddsColor ${arrowCss}`} onClick={() => !isMobile && clickHandlerFunc('label',id,{allupData})} >{oddsVal}</span>
                </div>
                <div className='col-gap-6px'>
                    {!isResultPage && <CheckBox control product='football' id={`fb_${id}`} classNameBox={`${id} ${isStopSelling && 'dim'}`} onClick={() => clickHandlerFunc('box',id,{curState, isSelling, context, setCheckboxState})} checked={curState.checked} disabled={isStopSelling} defaultChecked />}
                </div>
            </>
        }

        curState = {...curState,
            id: id,
            checked: curState?.checked || false,
            oddsVal: oddsVal,
            matchId: data.id,
            lineNum: lineNum,
            oddsType: foPool.oddsType,
            kickOffTime: data.kickOffTime
        }
        
        const { hG, aG } = getHandicap(foPool, context.content.config, isCloseLine, lineId);

        let allupData = {
            betType: foPool.oddsType,
            instNo: foPool.instNo,
            id: data.id,
            frontEndId: data.frontEndId,
            oddsVal,
            str: comb.selections[0]?.str,
            isSelling,
            name_en: comb.selections[0]?.name_en,
            name_ch: comb.selections[0]?.name_ch,
            homeTeam_en: data.homeTeam["name_en"],
            homeTeam_ch: data.homeTeam["name_ch"],
            awayTeam_en: data.awayTeam["name_en"],
            awayTeam_ch: data.awayTeam["name_ch"],
            lineNum,
            poolId: foPool.id,
            lineId,
            combId,
            result: 'W',
            condition: line.condition,
            hG,
            aG,
            inplayDelay: data.inplayDelay,
            tournCode: data.tournament?.code,
            tournName_en: data.tournament?.["name_en"],
            tournName_ch: data.tournament?.["name_ch"],
            foPool,
            matchInfo: {
                ...data,
                foPools: []
            }
        };

        let isChecked = context.content.oddsSelection.filter(x => x.id === id && x.checked)?.length > 0
        const oddsChangeCss = getOddsArrowCss(curState, isNaN(Number(oddsVal)))

        return <div className={`oddsCheckboxGrid ${hasO ? 'hasO' : ''} ${oddsChangeCss != '' ? 'oddsChanged' : ''}`} id={`fb_${foPool.oddsType}_${frontEndId}_${combStr}_${lineId}`}>
            {
                !isMobile ? <>
                    {showCombStr && <span className="crs-label" style={{minHeight: hasO}}>{toCRSLabel(combStr, t)}</span>}
                    <>
                        {!isResultPage && <CheckBox control product='football' id={`fb_${id}`} classNameBox={`${id} ${!isSelling && 'dim'}`} onClick={() => clickHandlerFunc('box',id,{curState, isSelling, context, setCheckboxState})} checked={isChecked} disabled={!isSelling} defaultChecked={curState.checked} />}

                    </>
                    <div className="oddsValue">
                        <div className={`oddsArrow ${oddsChangeCss}`}></div>
                        <span title={isResultPage ? null : t('LB_ALLUP_CALCULATOR')} className={`add-to-slip oddsColor ${oddsChangeCss} ${isUnClickable?'unCickable':''}`} onClick={() => clickHandlerFunc('label',id,{allupData})}>{oddsVal}</span>
                    </div>
                </> :
                    getMobileOddsItem(oddsVal, curState, !isSelling,  allupData)
            }
        </div>
    }, [context.content.oddsSelection, checkboxState, context.state.onClickOddsValue,data])

    function getTeamVal(match, type, handicap, isLine, isMobile) {

        let team = match[type.toLowerCase() + 'Team'];
        let isFGS = betType === 'FGS';

        if (team == null) {
            return <div>
                <span>{t('LB_FB_' + type + '_WIN')}</span>
            </div>
        } else if (isLine) {
            return <div>
                <span className='team-name'>{team['name_' + i18n.language] + handicap}</span>
            </div>
        } else {
            return <div>
                <span>
                    {
                        isMobile && !isFGS ?
                            `${t('LB_FB_' + type + '_S')} ${handicap}`
                            :
                            `${team['name_' + i18n.language]}${handicap} (${t('LB_FB_' + type + (isFGS ? '' : '_WIN'))})`
                    }
                </span>
            </div>
        }
    }


    const getMultipleLineOdds = (match, isAllOdds, isMobile, isHDCPool) => {
        let poolId = foPool.id;
        let curState = expandState.filter(x => x.id == poolId)[0];
        let isKickOff = isMatchKickOff(match.status);
        let lines = isCloseLine ? foPool.closedLines : foPool.lines;
        lines = isResultPage ? lines.filter(x=> !isCloseLine || !['SUSPENDED'].includes(x.status)) : lines.filter(x=> !['PAYOUTSTARTED', 'SUSPENDED', 'PAYOUTSTOPPED'].includes(x.status));
        lines.sort((x,y) => {
            if (globalContext.globalState.commonCtrl.mainlineOnTopDisplay || isHDCPool) {
                if ( x.main && !y.main) return -1;
                if ( !x.main && y.main) return 1;
            }
            if (isHDCPool){
                const xHomeOdds=x.combinations.find(i=>i.str==='H')?.currentOdds;
                const yHomeOdds=y.combinations.find(i=>i.str==='H')?.currentOdds;
                if (xHomeOdds != yHomeOdds) {
                    return Number(xHomeOdds) < Number(yHomeOdds) ? -1 : 1;
                }
                const xAwayOdds=x.combinations.find(i=>i.str==='A')?.currentOdds;
                const yAwayOdds=y.combinations.find(i=>i.str==='A')?.currentOdds;
                if (xAwayOdds != yAwayOdds) {
                    return Number(xAwayOdds) < Number(yAwayOdds) ? -1 : 1;
                }
                return x.lineId - y.lineId;
            }
            const xCondition= !x.condition.split('/')[1] ? x.condition.split('/')[0] : parseFloat(x.condition.split('/')[0]) + 0.25 ;
            const yCondition= !y.condition.split('/')[1] ? y.condition.split('/')[0] : parseFloat(y.condition.split('/')[0]) + 0.25;
            return parseFloat(xCondition) > parseFloat(yCondition) ? 1 : -1;
        });

        let isShowToggle = 0
        let line = lines.map((item, index) => {
            let lineNum = getGoalLine(item);
            let isSelling = isSellingStarted(match, foPool, item);
            // let isShowToggle = index == 0 && lines.length > 1;
            let showMain = lines.some(i => i.main);
            let toggleCss = ( showMain ? item.main : (index == 0)) || curState?.expand || isAllOdds ? 'show' : 'hide';
            if((toggleCss === 'show') && lines.length > 1) isShowToggle++
            if(isHDCPool){
                
                const { hG, aG } = getHandicap(foPool, context.content.config, isCloseLine, item.lineId);
                const showHADLabel = isAllOdds && isMobile
                return <Fragment key={`${poolId}_${lineNum}`}>
                
                <div className={`odds ${toggleCss}`}>
                    <div className='hdcOddsItem'>
                        <div className='cond'>{showHADLabel && t('LB_FB_HOME_S')}{hG}</div>
                        {getOddsVal(item, 'H', isMobile, lineNum)}
                    </div>
                    {getOddsVal(item, 'D', isMobile, lineNum) ? <div className='hdcOddsItem'>
                        <div className='cond'>{showHADLabel && t('LB_FB_DRAW_S')}</div>
                        {getOddsVal(item, 'D', isMobile, lineNum)}
                    </div> : null}
                    <div className='hdcOddsItem'>
                        <div className='cond'>{showHADLabel && t('LB_FB_AWAY_S')}{aG}</div>
                        {getOddsVal(item, 'A', isMobile, lineNum)}
                    </div>
                    {isShowToggle === 1 && <div onClick={() => handleSetLineState(match.matchDate, poolId)} className="couponBtn alloddshide">
                        <ArrowIcon id={`expand_${betType}_${data?.frontEndId}`}  className='hueRotate' direction='down' change={curState?.expand} changeDirection='up' size={20} />
                    </div>}
                </div>
            </Fragment>
            }
            return <Fragment key={`${poolId}_${lineNum}`}>
                <div className={`lineNum ${toggleCss}`} onClick={() => handleSetLineState(match.matchDate, poolId)}>
                    {!isResultPage && isKickOff && !isSelling ? '---' : `[${lineNum}]`}
                    {
                        isMobile && isShowToggle ===1 && <div>
                            <ArrowIcon direction={`${curState?.expand ? 'up' : 'down'}` } color='green' size={24} className='arrow-small'/>
                            </div>
                    }
                </div>

                <div className={`odds ${toggleCss}`}>
                    {getOddsVal(item, 'H', isMobile, lineNum)}
                    {getOddsVal(item, 'L', isMobile, lineNum)}
                    {getOddsVal(item, 'D', isMobile, lineNum)}
                    {getOddsVal(item, 'A', isMobile, lineNum)}
                    {isShowToggle === 1 && <div onClick={() => handleSetLineState(match.matchDate, poolId)} className="couponBtn alloddshide">
                        <ArrowIcon id={`expand_${betType}_${data?.frontEndId}`}  className='hueRotate' direction='down' change={curState?.expand} changeDirection='up' size={20} />
                    </div>}
                </div>
            </Fragment>
        })

        return <>{line}</>
    }

    const handleOnClick = (url, team) => {

        NewWindow(url, '', 770, 500, 1, 1);
    }

    let matchDate = toDateFormat(data.kickOffTime, true, true);
    let frontEndId = data.frontEndId;
    let tournamentShortName = data.tournament?.code;
    let tournamentName = data.tournament?.['name_' + i18n.language];
    let teamVS = data.homeTeam['name_' + i18n.language] + ' vs ' + data.awayTeam['name_' + i18n.language];

    let disableInplayClock = context.content.pageConfig.disableInplayClock;
    let isAllOddsPage = context.content.config.AllOddsPages.includes(context.content.page);
    let isMixAllUpDetailPage = context.content.config.MixAllUpDetailPages.includes(context.content.page);
    let isMixAllUpPage = context.content.config.MixAllUpPages.includes(context.content.page);
    let isInplayPage = context.content.config.InplayPages.includes(context.content.page);
    let isMobile = context.state.windowSize.isMobileSize;
    let poolId = foPool?.id;
    let curState = expandState?.filter(x => x.id === poolId)[0];
    const iscashoutPage=context.content.config.CashOutBetTypeList.includes(context.content.page);
    const isShowCashOutTicket=iscashoutPage && data.esIndicatorEnabled
        && ((globalContext.globalState.commonCtrl.earlySettlementEnabled
            && globalContext.globalState.commonCtrl.earlySettlementIndicatorEnabled) || window.globalConfig.IS_PREVIEW);

    const getTNLabelWidth = ()=>{
        const nameWidth = document.querySelector(`.tNLabel${frontEndId}`)?.offsetWidth;
        return nameWidth ? Math.ceil(nameWidth) + 2 : 0;
    }

    let showHDCMoreBtn =  window.fbConfig.HandicapPools.includes(betType) && foPool?.lines?.filter(x=> !['PAYOUTSTARTED', 'SUSPENDED', 'PAYOUTSTOPPED'].includes(x.status))?.length > 1

    const curProps = {getOddsVal, foPool, data, context, expandState,
        betType, isMultipleLineOdds, getMultipleLineOdds,disableInplayClock, globalContext , handleOnClick,
        isCloseLine,getTeamVal, hdcPool, setCheckboxState}

    if ((isAllOddsPage || isMixAllUpDetailPage) && context.content.config.FirstScorerPools.includes(betType)) {
        return <>
            <div className={`team ` + betType.toUpperCase()}>
                {getTeamVal(data, 'HOME', '', false, isMobile)}
                {getTeamVal(data, 'AWAY', '', false, isMobile)}
            </div>
            <FGSTable foPool={foPool} isMobile={isMobile} getOddsVal={getOddsVal} />
        </>
    } else if ((isAllOddsPage || isMixAllUpDetailPage) && context.content.config.SpecialPools.includes(betType)) {
        return <SPCTable betType={betType} isMatch={true} match={data} showCoupon={false} isResultPage={isResultPage} />;
    } else if ((isAllOddsPage || isMixAllUpDetailPage) && context.content.config.SGAPools.includes(betType)) {
        curState = expandState?.filter(x => x.id === "SGA")[0];
        return <SGATable match={data} isResultPage={isResultPage} expand={curState?.expand} handleSetLineState={handleSetLineState} />;
    } else if (context.content.config.NTSPools.includes(betType)) {
        return <>
            <div className={`team ` + betType.toUpperCase()}>
                {isResultPage && <div></div>}
                {getTeamVal(data, 'HOME', '', false, isMobile)}
                <div>{t('LB_FB_NO_GOALS_WIN')}</div>
                {getTeamVal(data, 'AWAY', '', false, isMobile)}
            </div>

            {isResultPage ?
            <NTSLastOddsTable betType={betType} match={data} isMobile={isMobile} getOddsVal={getOddsVal} />
            : <Odds isAllOdds={true} isMobile={isMobile} {...curProps}/>}
        </>
    } else if ((isAllOddsPage || isMixAllUpDetailPage) && context.content.config.CorrectScorePools.includes(betType)) {
        return <>
            <div className={`team ` + betType.toUpperCase()}>
                {getTeamVal(data, 'HOME', '', false, isMobile)}
                {getTeamVal(data, 'DRAW', '', false, isMobile)}
                {getTeamVal(data, 'AWAY', '', false, isMobile)}
            </div>
            <CRSTable foPool={foPool} isMobile={isMobile} getOddsVal={getOddsVal} isCloseLine={isCloseLine} />
            <CRSTopTips {...crsTipsPorops} />
        </>
    } else if (isAllOddsPage || isMixAllUpDetailPage) {
        let line = isCloseLine ? getAvailableLine(foPool.closedLines, isResultPage)[0] : getAvailableLine(foPool.lines, isResultPage)[0];
        let hideHDCTeam = context.content.config.HandicapPools.includes(betType) && isMobile
        return <>
            {hideHDCTeam ? <div></div> :
                <div className={`team ` + betType.toUpperCase()}>
                    {isMultipleLineOdds ? <>
                        <div>{t('LB_FB_LINE')}</div>
                        {getCombination(line, 'H') && getTeamVal(data, 'HIGH', '', false, isMobile)}
                        {getCombination(line, 'L') && getTeamVal(data, 'LOW', '', false, isMobile)}
                    </> : <>
                        {getCombination(line, 'H') && getTeamVal(data, 'HOME',  '', false, isMobile)}
                        {getCombination(line, 'D') && getTeamVal(data, 'DRAW', '', false, isMobile)}
                        {getCombination(line, 'N') && getTeamVal(data, 'NO_GOALS', '', false, isMobile)}
                        {getCombination(line, 'A') && getTeamVal(data, 'AWAY',  '', false, isMobile)}
                        {getCombination(line, 'O') && getTeamVal(data, 'ODD', '', false, isMobile)}
                        {getCombination(line, 'E') && getTeamVal(data, 'EVEN', '', false, isMobile)}
                    </>}
                </div>}
            <Odds isAllOdds={true} isMobile={isMobile} {...curProps}/>
        </>
    } else if (isMixAllUpPage) {
        return <>
            {

                !context.state.windowSize.isMobileSize ? <>
                    <div className='match-row' id={`${betType}_${frontEndId}`}>
                        <MixSelection data={data} isMobile={context.state.windowSize.isMobileSize} {...curProps}/>
                        <div className='right-border date'>{matchDate}</div>
                        <div className='right-border fb-id'>{frontEndId}</div>
                        <div className='tourn right-border'><TournImage key={`flag_${tournamentShortName}`} code={tournamentShortName} name={tournamentName} /></div>
                        <Team match={data} {...curProps}/>
                        <div className='oddsLine'></div>
                        <InplayClock match={data} foPool={foPool} isMobileSize={context.state.windowSize.isMobileSize} />
                    </div>
                </> : <>
                    <div className='match-row-m' id={`${betType}_${frontEndId}`}>
                        <div>
                            <div>
                                <div>{frontEndId}</div>
                                <div className='tourn tournName-m'>
                                    <span className={`tNLabel${frontEndId}`}>{tournamentName}</span>
                                    {isShowCashOutTicket ? <div className='tourn_es_icon_m' title={t('LB_CASHOUT')} style={{left:`${getTNLabelWidth()}px`}}></div> : null}
                                </div>
                            </div>
                            <div>
                                <TeamMobile match={data} {...curProps}/>
                            </div>
                            <div className='clock-row'>
                                <InplayClock match={data} foPool={foPool} isMobileSize={context.state.windowSize.isMobileSize} />
                            </div>
                        </div>
                        <MixSelection data={data} isMobile={context.state.windowSize.isMobileSize} {...curProps}/>
                    </div>
                </>
            }
        </>
    } else {
        return <>
            {
                !context.state.windowSize.isMobileSize ? <>
                    <div className='match-row' id={`${betType}_${frontEndId}`}>
                        <div className="right-border date">{matchDate}</div>
                        <div className="right-border fb-id">{frontEndId}</div>
                        <div className='tourn right-border'><TournImage key={`flag_${tournamentShortName}`} code={tournamentShortName} name={tournamentName} /></div>
                        <Team match={data} disableInplayClock={disableInplayClock} {...curProps}/>
                        {isInplayPage && <LiveInfo t={t} match={data} />}
                        {!disableInplayClock && <InplayClock match={data} foPool={foPool} isMobileSize={context.state.windowSize.isMobileSize} />}
                        <Odds foPool={foPool} isAllOdds={false} isMobile={false} {...curProps}/>
                        <H2H matchId={data.id} tournId={data.tournament?.id} team={teamVS} {...curProps}/>
                    </div>
                </> : <div className='match-row-m-container'>
                    <div className='match-row-m' id={`${betType}_${frontEndId}`}>
                        <div className='matchInfo'>
                            <div>
                                <div className='tourn'>
                                    <div>{frontEndId}</div>
                                    <span> </span>
                                    <div className='tournName-m'>
                                        <span className={`tNLabel${frontEndId}`}>{tournamentName}</span>
                                        {isShowCashOutTicket ? <div className='tourn_es_icon_m' title={t('LB_CASHOUT')} style={{left:`${getTNLabelWidth()}px`}}></div> : null}
                                    </div>
                                </div>
                                <TeamMobile match={data} {...curProps}/>
                            </div>
                            <div className='clock-row'>
                                <InplayClock match={data} foPool={foPool} isMobileSize={context.state.windowSize.isMobileSize} />
                            </div>
                        </div>
                        <Odds foPool={foPool} isAllOdds={false} isMobile={true} {...curProps}/>
                        {isInplayPage ? <div className='LiveInfo-and-H2H'><LiveInfo t={t} match={data} onlyShowLiveInfo={true} /><H2H matchId={data.id} tournId={data.tournament?.id} team={teamVS} {...curProps}/></div> : <H2H matchId={data.id} tournId={data.tournament?.id} team={teamVS} {...curProps}/>}
                        {
                            ((['TTG', 'ETG', 'HFT'].includes(betType) && foPool) || showHDCMoreBtn) && <div className={`more`} onClick={() =>  handleSetLineState(data.matchDate, poolId)}>
                                <span>{t('LB_FB_' + (curState?.expand ? 'LESS' : 'MORE'))}</span>
                                <ArrowIcon direction={`${curState?.expand ? 'up': 'down'}` } color='green' size={24} />
                            </div>
                        }
                    </div>
                </div>
            }
            <CRSTopTips {...crsTipsPorops} />
        </>
    }
}
export default Row;

export const  getAvailableLine = (lines, isResultPage)=>{
    if(!lines) return []
    return isResultPage ? lines : lines.filter(line=> line.status === 'AVAILABLE')
}

const Odds = ({ isAllOdds, isMobile, getOddsVal, foPool, data, context, expandState, betType, isMultipleLineOdds, getMultipleLineOdds, isCloseLine,getTeamVal, hdcPool, }) => {
    const {t, i18n} = useTranslation();
    const isResultPage = isMatchResultPage(context.content.page);

    if (["HOME", "HAD"].includes(context.content.page) && data.adminOperation?.remark?.typ=="A") {
        return <div className='odds oddsHAD hadstopsell announced'>
            <div>{t('LB_FB_TOBEANNOUCED')}</div>
        </div>
    }

    let etBetType = getETBetType(betType, data)

    const betTypeName = etBetType ?  t(`LB_FB_ETBETTYPE_${etBetType}`) : betType=== 'HAD' ? t('LB_FB_TITLE_' + betType + '_S') : t('LB_FB_TITLE_' + betType)
    if (foPool == null) {
        let isInplay = isMatchKickOff(data.status);
        return <div className={`odds oddsLine ${betType} oddsHAD hadstopsell`}
            onClick={() => goToAllOddsPage(data, context)}>
            <div>{isInplay ? t('LB_FB_CLICK_TO_OTHER_INPLAY_POOLS') : t('LB_FB_CLICK_TO_OTHER_POOLS')}</div>
            <div>({t('LB_FB_POOL_STOPPED_SELL').replace('{0}', betTypeName)})</div>
        </div>
    }

    let poolId = foPool.id;
    let curState = expandState?.filter(x => x.id == poolId)[0];
    let isKickOff = isMatchKickOff(data.status);
    let isSelling = isSellingStarted(data, foPool);
    const showSuspended= !isResultPage && !isSelling && isKickOff;
    
    if (isMultipleLineOdds) {
        return <div className={`oddsLine ` + betType}>{!isAllOdds && showSuspended && <div className='suspended isMultipleLineOdds'>{t('LB_FB_SUSPENDED')}</div>}{getMultipleLineOdds(data, isAllOdds, isMobile)}</div>
    } else if (context.content.config.HandicapPools.includes(betType)) {
        return <div className={`oddsLine ` + betType}>{!isAllOdds && showSuspended && <div className='suspended isMultipleLineOdds'>{t('LB_FB_SUSPENDED')}</div>}{getMultipleLineOdds(data, isAllOdds, isMobile, true)}</div>
    } else if (context.content.config.CorrectScorePools.includes(betType)) {
        let highHeight = i18n.language == 'en' ? isMobile ? 58: 44 : isMobile ? 42: 34
        let lowHeight = i18n.language == 'en' ? isMobile ? 28: 24 : null
        let combStrs = getFirstNCombStr(foPool.lines[0], isMobile?2:4)
        let hasO =combStrs.some(x=> ['OHN', 'OAN', 'HOAS', 'AOHS', 'HOAN', 'AOHN'].includes(x)) ? highHeight : combStrs.some(x=> ['HO', 'DO', 'AO'].includes(x)) ? lowHeight : undefined
        return <><div className={`oddsLine ` + betType}>
            <div className={`crs-odds-box`}>
                {showSuspended && <div className='suspended'>{t('LB_FB_SUSPENDED')}</div>}
                {combStrs.map((x, i)=> {
                    if ( !isMobile && window.innerWidth < 1440 ) {
                        if(i === 0 || i === 1) {
                            let arr = [combStrs?.[0], combStrs?.[1]]
                            hasO =arr.some(x=> ['OHN', 'OAN', 'HOAS', 'AOHS', 'HOAN', 'AOHN'].includes(x)) ? highHeight : arr.some(x=> ['HO', 'DO', 'AO'].includes(x)) ? lowHeight : undefined
                        } else if(i === 2 || i === 3) {
                            let arr = [combStrs?.[2], combStrs?.[3]]
                            hasO =arr.some(x=> ['OHN', 'OAN', 'HOAS', 'AOHS', 'HOAN', 'AOHN'].includes(x)) ? highHeight : arr.some(x=> ['HO', 'DO', 'AO'].includes(x)) ? lowHeight : undefined
                        } else {
                            hasO = undefined
                        }
                    }
                    return <Fragment key={x}>{getOddsVal(getAvailableLine(foPool.lines, isResultPage)[0], x, false, 0, true, hasO)}</Fragment>;
                })}
            </div>
            <div className="crs-more-button" onClick={() => goToAllOddsPage(data, context, foPool.oddsType)}><div>{t('LB_FB_MORE')}</div></div>
        </div>
        </>
    } else if (context.content.config.FirstScorerPools.includes(betType)
        || context.content.config.SpecialPools.includes(betType)
        || context.content.config.SGAPools.includes(betType)) {
        return <div className={`oddsLine ` + betType}>
            <div className="crs-more-button" onClick={() => goToAllOddsPage(data, context, betType)}><div>{t('LB_FB_MORE')}</div></div>
        </div>
    } else if (isAllOdds) {
        let curOdds = isCloseLine ? getAvailableLine(foPool.closedLines, isResultPage)[0] : getAvailableLine(foPool.lines, isResultPage)[0];
        return <div className={`odds odds` + betType}>
            {hdcPool && !isMobile && <div>
                {getTeamVal(data, 'HOME', '', true, isMobile)}
                {getTeamVal(data, 'AWAY', '', true, isMobile)}
            </div>}
            {getOddsVal(curOdds, 'H', isMobile)}
            {getOddsVal(curOdds, 'N', isMobile)}
            {getOddsVal(curOdds, 'D', isMobile)}
            {getOddsVal(curOdds, 'A', isMobile)}
            {getOddsVal(curOdds, 'O', isMobile)}
            {getOddsVal(curOdds, 'E', isMobile)}
            {['TTG', 'ETG'].includes(betType) && <NthColsTable foPool={foPool} combStrs={context.content.config.TTGCombStrs}
                lblFunc={(str) => { return str }}
                isMobile={isMobile} getOddsVal={getOddsVal} expand={true} isCloseLine={isCloseLine} />}
            {['HFT'].includes(betType) && <NthColsTable foPool={foPool} combStrs={context.content.config.HFTCombStrs}
                lblFunc={(str) => { return `${t('LB_FB_' + str.substring(0, 1) + '_S')}-${t('LB_FB_' + str.substring(2, 3) + '_S')}` }}
                isMobile={isMobile} getOddsVal={getOddsVal} expand={true} isCloseLine={isCloseLine} />}
        </div>
    } else {
        let curOdds = isCloseLine ? getAvailableLine(foPool.closedLines, isResultPage)[0] : getAvailableLine(foPool.lines, isResultPage)[0];
        return < div className={`odds ${isMobile && (curState?.expand ? 'expand' : 'collaspse')}`} >
            {showSuspended && <div className='suspended'>{t('LB_FB_SUSPENDED')}</div>}
            {getOddsVal(curOdds, 'H', isMobile)}
            {getOddsVal(curOdds, 'N', isMobile)}
            {getOddsVal(curOdds, 'D', isMobile)}
            {getOddsVal(curOdds, 'A', isMobile)}
            {getOddsVal(curOdds, 'O', isMobile)}
            {getOddsVal(curOdds, 'E', isMobile)}

            {['TTG', 'ETG'].includes(betType) && <NthColsTable foPool={foPool} combStrs={context.content.config.TTGCombStrs}
                lblFunc={(str) => { return str }}
                isMobile={isMobile} getOddsVal={getOddsVal} expand={curState?.expand} />}
            {['HFT'].includes(betType) && <NthColsTable foPool={foPool} combStrs={context.content.config.HFTCombStrs}
                lblFunc={(str) => { return `${t('LB_FB_' + str.substring(0, 1) + '_S')}-${t('LB_FB_' + str.substring(2, 3) + '_S')}` }}
                isMobile={isMobile} getOddsVal={getOddsVal} expand={curState?.expand} />}
        </div >
    }
}

const MixSelection = ({ data, isMobile, context, setCheckboxState }) => {

    let id = 'MIXALLUP_' + data.id;
    let curState = context.content.oddsSelection.filter(x => x.id == id)[0];

    if (curState == null) {
        curState = {
            id: id,
            checked: false,
            kickOffTime: data.kickOffTime,
            oddsType: 'MIXALLUP'
        }
    }

    return <>

        <div className={` ${!isMobile && 'right-border'}`}>
            <CheckBox  product='football' id={`fb_${id}`} classNameBox={id} onClick={() => onClickCheckBox(curState, false, context, setCheckboxState)} defaultChecked={curState.checked} />
        </div>
    </>
}

function TeamMobile({ match, foPool, data, context }) {
    const {t, i18n} = useTranslation()
    let team = <>
        <span>{match.homeTeam['name_' + i18n.language]}</span>
        <span>{match.awayTeam['name_' + i18n.language]}</span>
    </>

    let isKickOff = isMatchKickOff(match.status);
    let isET = isExtraTimeMatch(match)
    let isInplay = foPool != null ? foPool.inplay : match.poolInfo.inplayPools.length > 0;
    let { homeScore, awayScore } = getRunningResult(match);
    if ( match.poolInfo.sellingPools.length==0 && !isMatchCompletedStatus(match.status) ) {
        homeScore = "-";
        awayScore = "-";
    }
    let isMixAllUpPage = context.content.config.MixAllUpPages.includes(context.content.page);
    let disableScore = isMixAllUpPage && context.content.allupSelection.length === 0
    let showScorePage = window.fbPageConfig[context.content.page].showInplayMatch

    let score = null;
    if (isInplay && isKickOff && !disableScore && showScorePage) {
        score = <div className={isET ? "et" : ""}>
            <span>{homeScore}</span>
            <span>{awayScore}</span>
        </div>
    }

    return <div className='team'>
        <div onClick={() => goToAllOddsPage(match, context)}>{team}</div>
        {score}
        {/* {isMixAllUpPage && <Venue singleMatch={data} t={t} lang={i18n.language} />} */}
    </div>
}

function Team({ match, foPool, data, context, disableInplayClock  }) {

    const {t, i18n} = useTranslation();
    const globalContext = useContext(GlobalContext);
    let team = <>
        <div>{match.homeTeam['name_' + i18n.language]}</div>
        <div>{match.awayTeam['name_' + i18n.language]}</div>
    </>

    let isMixAllUpPage = context.content.config.MixAllUpPages.includes(context.content.page);
    let isKickOff = isMatchKickOff(match.status);
    let isET = isExtraTimeMatch(match);
    let isInplayPage = context.content.config.InplayPages.includes(context.content.page);

    let isInplay = foPool != null ? foPool.inplay : match.poolInfo?.inplayPools.length > 0;
    const iscashoutPage=context.content.config.CashOutBetTypeList.includes(context.content.page);
    const isShowCashOutTicket=iscashoutPage && match.esIndicatorEnabled
        && ((globalContext.globalState.commonCtrl.earlySettlementEnabled
        && globalContext.globalState.commonCtrl.earlySettlementIndicatorEnabled) || window.globalConfig.IS_PREVIEW);
    const clientWidth=context.state.windowSize.width;
    let score = null;
    let { homeScore, awayScore } = getRunningResult(match);
    if ( match.poolInfo.sellingPools.length==0 && !isMatchCompletedStatus(match.status) ) {
        homeScore = "-";
        awayScore = "-";
    }

    let disableScore = isMixAllUpPage && context.content.allupSelection.length === 0
    let showScorePage = window.fbPageConfig[context.content.page].showInplayMatch

    if (isInplay && isKickOff && !disableScore && showScorePage) {
        score = <div className={`score ${isET ? "et" : ""}`}>
            <span>{homeScore}</span>
            <span>{awayScore}</span>
        </div>
    }

    let altTxt = isMatchKickOff(match.status) || isInplayPage ? t('LB_FB_TITLE_INPLAY') : t('LB_FB_TITLE_ALLODDS');

    const teamName=<div onClick={() => goToAllOddsPage(match, context)} title={altTxt}>{team}</div>;
    const teamIcon=<div className='teamIconState'>
        {isShowCashOutTicket ? <div><div className='tourn_es_icon' title={t('LB_CASHOUT')}></div></div> : null}
        <TVChannel match={match} />
        <Venue singleMatch={data} t={t} lang={i18n.language} />
    </div>;

    const teamAndIcon=<>
        <div className='teamIconSmall'>
            {teamName}
            {teamIcon}
        </div>
        <div></div>
    </>;

    return <div className={`team ${disableInplayClock ? 'right-border' : ''}`}>
        {teamAndIcon}
        {score}
    </div>
}

function H2H({ matchId, tournId, team, globalContext , handleOnClick}) {
    const {t, i18n} = useTranslation()

    let url = window.globalConfig.STAT_CENTER_URL + t('URL_FB_H2H').replace('{0}', tournId).replace('{1}', matchId);
    let h2hEnabled = globalContext.globalState.commonCtrl?.headToHeadIconEnabled;

    return h2hEnabled ? <div className='image-H2H image-H2H-item' onClick={() => handleOnClick(url, team)} title={t('LB_FB_H2H')}></div> : <div className='space16px'></div>

}