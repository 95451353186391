import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useBetSlip } from '../../BetSlip/BetSlipHooks';
import { RacingContext } from '../../Home/RacingPage';
import { GetBetSels, CalculateBet, GetSelectedBet, GetCalBetObj, ClearBetSels, CalculateAlupBet, GetBetSelsRandom,SetRandomBetSelect, RandomLeg, SetSubType, calBet, ClearBetByBetType } from './RacingBetline';
import { rcAddToSlipValidation } from './AddSlipValidation';
import { ConfigContext } from '../../Common/ConfigHelper';
import { IsFlexibetEnabled } from '../../Common/ChannelParaFunc';
import { getDefaultRacingUnitBet } from './Common';
import { WATrackerTrackClickEvent } from '../../Common/utils/wa';
import { glassboxCustomEvent } from '../../Common/utils/glassbox';
import { getPersonalSettings } from '../../Home/PersonalSettings/config'
import { GlobalContext } from '../../../contexts/GlobalContext';

const AddSlipBtn = (props, ref) => {
    const { t } = useTranslation();
    const { addToBetSlip, betLines } = useBetSlip();
    const btnRef = useRef();
    const context = useContext(RacingContext);
    const config = useContext(ConfigContext);
    const globalContext = useContext(GlobalContext);
    const racingConfig = config?.racing;
    const { FOSelectedBet, setFOSelectedBet } = context;

    const handleClickAddSlipBtn = ({ qucikPickNoOfBets, quickPickSels }) => {
        const meeting = context.content.meeting;
        let selections = GetBetSels();
        const multLegPool = racingConfig?.MultiRacePage;
        const allUpPage = racingConfig.ALUP_PAGE;

        const isAllUpPage = allUpPage.includes(context.content.page) || context.content.alupFormula;

        if (!rcAddToSlipValidation(selections, context, t, isAllUpPage, globalContext)) return;

        if (['WP', 'JTCOMBO', 'PWIN'].includes(context.content.page) && !isAllUpPage) {
            addSingleSelect(selections, meeting);
        } else if (['JKC', 'TNC'].includes(context.content.page)) {
            addFOSelect(FOSelectedBet, context, betLines);
        } else if (multLegPool.includes(context.content.page) || context.content.alupData.length > 1) {

            addMultRaceSelect(selections, context, isAllUpPage, qucikPickNoOfBets, quickPickSels);
        } else if (['CWA', 'CWB', 'CWC'].includes(context.content.page)) {
            addCWINSelect(selections, context);
        } else if (['CWAALLALUP'].includes(context.content.page)) {
            let filteredSelections = {};
            for ( let idx in selections ) {
                if ( selections[idx] ) {
                    filteredSelections[idx] = true;
                }
            }
            const multRaces = Object.keys(filteredSelections).map((item) => {
                const [betType, raceNo] = item.split('_');
                return Number(raceNo);
            });
            const alupData = [...new Set(multRaces)].map((raceNo) => ({ raceNo, betType: 'CWA' }));
            if (alupData.length === 1) {
                addCWINSelect(filteredSelections, context, alupData?.[0]);
            } else {
                addMultRaceSelect(filteredSelections, { ...context, alupData }, true);
            }
        } else {
            if (context.content.alupData?.[0]?.betType === 'CWA') {
                addCWINSelect(selections, context, context.content.alupData?.[0]);
            } else {
                addMultiSelect(selections, meeting);
            }
        }
        if (isAllUpPage) {
            WATrackerTrackClickEvent("betslip-hr-addbet-01");
            glassboxCustomEvent("All_Up_Calculator_Add_to_Slip", "Racing_All_Up");
        } else {
            glassboxCustomEvent("Add_to_Slip");
        }
    };

    const clearCheckboxes = (cancelTickNum = 999, addBetList = []) => {
        if (cancelTickNum == 999) {
            ClearBetSels();
            context.contentRef.current.betCount = '-';
        } else {
            let num = 0;
            for (let i = 0; i < addBetList.length; i++) {
                const item = addBetList[i];
                ClearBetByBetType(item.betType, item.raceno);
                num++;
                if (num == cancelTickNum) {
                    break;
                }
            }
            context.contentRef.current.betCount = calBet(context.contentRef);
        }
        // context.content.alupFormula = '';
        if (['JTCOMBO'].includes(context.contentRef.current.page) && context.contentRef.current.alupFormula) {
            context.contentRef.current.alupFormula = ''
        }
        context.racingFuncs.setContent({ ...context.contentRef.current });
    };

    const addSingleSelect = (selections, meeting) => {
        const betLineList = [];
        Object.entries(selections).forEach(([item, value]) => {
            if (value == true) {
                const [betType, raceNo, leg, runnerNo] = item.split('_');

                const runner = getRunnerDetails(raceNo, runnerNo);
                const oldBetObj = betLineList.find((betObj) => betObj.betType === betType && betObj.raceno === raceNo);
                if (oldBetObj !== undefined) {
                    oldBetObj.combs.push(runner);
                    sortRunners(oldBetObj);
                    oldBetObj.noOfBets = betType == 'W-P' ? oldBetObj.noOfBets + 2 : oldBetObj.noOfBets + 1;
                    if (context.content.betTotal != '-') oldBetObj.betTotal = parseInt(context.content.betTotal);
                    else oldBetObj.betTotal = oldBetObj.unitBet * oldBetObj.noOfBets;
                } else {
                    let betObj = {};
                    const availableRunners = meeting.races.find((item) => item.no == raceNo)?.runners?.filter((runner) => !['SCRATCHED', 'SCRATCHEDRESERVED', 'STANDBY'].includes(runner.status.toUpperCase()));
                    const noOfBets = runnerNo === 'F' ? (betType == 'W-P' ? availableRunners?.length * 2 : availableRunners?.length) : betType == 'W-P' ? 2 : 1;
                    betObj.prod = 'HR';
                    betObj.betType = betType;
                    betObj.subType = racingConfig.hasSubType.includes(betType) ? context.content?.subMethod?.type : undefined;
                    betObj.venueid = meeting.venueCode;
                    betObj.dayid = meeting.dateOfWeek;
                    betObj.poolid = betType;
                    betObj.raceno = raceNo;
                    betObj.noOfBets = noOfBets;
                    betObj.id = `${Date.now()}#${betType}#${meeting.id}#${raceNo}#${runnerNo}`;
                    betObj.combs = [runner];
                    betObj.meetingId = meeting.id;
                    betObj.useFlexiUi = IsFlexibetEnabled(betType);
                    betObj.isFlexi = context.content.flexibet;
                    if (context.content.unitBet != '-' && !['JTCOMBO'].includes(context.content.page)) betObj.unitBet = parseInt(context.content.unitBet);
                    else betObj.unitBet = getDefaultRacingUnitBet(betType);
                    if (context.content.betTotal != '-' &&  context.content.flexibet) betObj.betTotal = parseInt(context.content.betTotal);
                    else betObj.betTotal = betObj.unitBet * betObj.noOfBets;

                    betLineList.push(betObj);
                }
            }
        });

        let successAdd = addToBetSlip(betLineList)
        if(successAdd.acceptResult){
            clearCheckboxes(successAdd.acceptType == 'part' ? successAdd.acceptBetSlipLength : 0, betLineList);
        }
    };

    const addMultiSelect = (selections, meeting) => {
        const betLineList = [];
        const raceNo = context.content.alupData.length>=1 ? context.content.alupData[0].raceNo : context.content.raceNo;
        let betType;
        Object.entries(selections).some(([item, value]) => {
            if (value == true) {
                betType = item.split('_')[0];
                return betType;
            }
        });

        let betObj = {};
        betObj.prod = 'HR';
        betObj.betType = betType;
        let subType= racingConfig.hasSubType.includes(betObj.betType) ?
            (context.content.alupData.length>=1 ? context.content.alupData[0].subType : context.content?.subMethod?.type)
            : undefined;
        betObj.subType =  subType;
        SetSubType(subType);
        betObj.venueid = meeting.venueCode;
        betObj.dayid = meeting.dateOfWeek;
        betObj.poolid = betType;
        betObj.raceno = raceNo;
        betObj.noOfBets = CalculateBet(meeting, GetCalBetObj());
        betObj.isRandom = GetBetSelsRandom();
        betObj.useFlexiUi = IsFlexibetEnabled(betType);
        betObj.isFlexi = context.content.flexibet;
        if (context.content.unitBet != '-') betObj.unitBet = context.content.unitBet;
        else betObj.unitBet = getDefaultRacingUnitBet(betType);
        if (context.content.betTotal != '-') betObj.betTotal = parseInt(context.content.betTotal);
        else betObj.betTotal = betObj.unitBet * betObj.noOfBets;

        let banker1 = [];
        let banker2 = [];
        let banker3 = [];

        const betsB1 = GetSelectedBet(betType, '*', 'b1');
        const betsB2 = GetSelectedBet(betType, '*', 'b2');
        const betsB3 = GetSelectedBet(betType, '*', 'b3');

        betsB1.map((item) => {
            banker1.push(getRunnerDetails(raceNo, item));
        });
        betsB2.map((item) => {
            banker2.push(getRunnerDetails(raceNo, item));
        });
        betsB3.map((item) => {
            banker3.push(getRunnerDetails(raceNo, item));
        });
        betObj.bankers = [banker1, banker2, banker3];

        const betsLeg = GetSelectedBet(betType, raceNo, 'leg');
        let combs = [];
        betsLeg.map((item) => {
            combs.push(getRunnerDetails(raceNo, item));
        });
        betObj.combs = combs;
        betObj.meetingId = meeting.id;
        betObj.id = Date.now().toString();

        sortRunners(betObj);
        updateBankersBySubtype(betObj);
        betLineList.push(betObj);
        if (addToBetSlip(betLineList).acceptResult) {
            clearCheckboxes();
        }
    };

    const addMultRaceSelect = (selections, context, isAllUpPage, qucikPickNoOfBets, qucikPickSels) => {
        const mtg = context.content.meeting;
        const betObj = {};
        const selectCombObj = {};
        const sels = qucikPickSels || [selections]
        sels.forEach((selObj, index) => {
            Object.keys(selObj)
                .filter((key) => {
                    if (selObj[key]) return key;
                })
                .forEach((item) => {
                    const [betType, raceNo, selectType, combStr] = item.split('_');
                    const newKey = `${raceNo}_${index}`

                    if (selectCombObj[newKey]) {
                        if (selectCombObj[newKey][selectType]) {
                            selectCombObj[newKey][selectType].push({
                                id: item,
                                betType,
                                raceNo,
                                selectType,
                                combStr
                            });
                        } else {
                            selectCombObj[newKey][selectType] = [
                                {
                                    id: item,
                                    betType,
                                    raceNo,
                                    selectType,
                                    combStr
                                }
                            ];
                        }
                    } else {
                        selectCombObj[newKey] = {
                            betType: betType
                        };
                        selectCombObj[newKey][selectType] = [
                            {
                                id: item,
                                betType,
                                raceNo,
                                selectType,
                                combStr
                            }
                        ];
                    }
                })
        })

        betObj.prod = 'HR';
        betObj.allupFormula = context.content.alupFormula;
        let betType = isAllUpPage && context.content.page === 'JTCOMBO' ? "WPALUP" : context.content.page;
        betObj.betType = betType;
        betObj.subType =  racingConfig.hasSubType.includes(betObj.betType) ?
            (context.content.alupData.length>=1 ? context.content.alupData[0].subType : context.content?.subMethod?.type)
             : undefined;
        betObj.venueid = context.content.venue;
        betObj.dayid = mtg.dateOfWeek;
        betObj.poolid = context.content.page;
        betObj.noOfBets = qucikPickNoOfBets || (isAllUpPage ? CalculateAlupBet(mtg, context.content.alupData, context.content.alupFormula, GetCalBetObj()) : CalculateBet(mtg, GetCalBetObj()));
        betObj.isRandom = GetBetSelsRandom();
        betObj.betList = [];
        betObj.meetingId = mtg.id;
        betObj.id = Date.now().toString();
        betObj.useFlexiUi = IsFlexibetEnabled(betObj.betType);
        betObj.isFlexi = context.content.flexibet;
        if (context.content.unitBet != '-') betObj.unitBet = context.content.unitBet;
        else betObj.unitBet = getDefaultRacingUnitBet(betType);
        if (context.content.betTotal != '-') betObj.betTotal = parseInt(context.content.betTotal);
        else betObj.betTotal = betObj.unitBet * betObj.noOfBets;
        if (GetBetSelsRandom() && betObj.betType === 'TT') {
            switch (betObj.noOfBets) {
                case 64:
                    betObj.unitBet = 2;
                    break;
                case 27:
                    betObj.unitBet = 5;
                    break;
                default:
                    betObj.unitBet = 10;
            }
            betObj.betTotal = betObj.unitBet * betObj.noOfBets;
            betObj.useFlexiUi = false;
            betObj.isFlexi = false;
            betObj.fixRandom = true;
        }

        const newSelectCombList = Object.entries(selectCombObj).sort((a,b)=> {
            let [a1, a2] = a[0].split('_')
            let [b1, b2] = b[0].split('_')
            return a1 - b1
        }).sort((a, b) => {
            let [a1, a2] = a[0].split('_')
            let [b1, b2] = b[0].split('_')
            return a2 - b2
        })
        newSelectCombList.forEach(([key, item]) => {
            const raceNo = parseInt(key)
            const currentAlupData = context.content.alupData.find((item) => item.raceNo == raceNo);
            const combItem = {
                raceNo,
                betType: item.betType,
                subType: racingConfig.hasSubType.includes(item.betType) ? currentAlupData?.subType : undefined
            };
            const b1 =
                item?.b1?.map((i) => {
                    return getRunnerDetails(i.raceNo, i.combStr);
                }) || [];
            const b2 =
                item?.b2?.map((i) => {
                    return getRunnerDetails(i.raceNo, i.combStr);
                }) || [];
            const b3 =
                item?.b3?.map((i) => {
                    return getRunnerDetails(i.raceNo, i.combStr);
                }) || [];
            const leg =
                item?.leg?.map((i) => {
                    if (i.betType === 'CWA') {
                        const poolId = `${mtg.id}${i.betType}${raceNo}`;
                        const currentPool = mtg.pmPools.find((pool) => pool.id === poolId);
                        const currentComb = currentPool?.cWinSelections?.find((item) => item.composite === i.combStr);
                        const combItem = [];
                        currentComb.starters.forEach((runnerNo) => {
                            combItem.push(getRunnerDetails(raceNo, runnerNo));
                        });
                        return {
                            composite: currentComb.composite,
                            name_ch: currentComb.name_ch,
                            name_en: currentComb.name_en,
                            combs: combItem
                        };
                    }
                    return getRunnerDetails(i.raceNo, i.combStr);
                }) || [];
            combItem.bankers = [b1, b2, b3];
            combItem.combs = leg;
            sortRunners(combItem);
            updateBankersBySubtype(combItem);
            betObj.betList.push(combItem);
        });

        if (addToBetSlip([betObj]).acceptResult) {
            clearCheckboxes();
        }
    };

    const addFOSelect = (selections, context, betLines) => {
        const mtg = context.content.meeting;
        let currentPoolId;
        let quickBetId = '';
        const combs = [];
        Object.keys(selections).forEach((id) => {
            if (!selections[id].checked) return;
            const [betType, meetingId, poolId, combId] = id.split('#');
            currentPoolId = poolId;
            combs.push({...selections[id].sel, poolId});
            quickBetId += id;
        });
        const currentPool = mtg.foPools.find((pool) => currentPoolId === pool.poolId);
        if (!quickBetId) return;
        const unitBet = parseInt(getPersonalSettings('racing', context.content.page), 10)
        const betObj = {
            prod: 'HR',
            venueid: context.content.venue,
            dayid: mtg.dateOfWeek,
            id: quickBetId,
            betType: context.content.page,
            noOfBets: combs.length,
            unitBet,
            betTotal: combs.length * unitBet,
            currentPool,
            combs: combs.sort((a,b) => a.number - b.number)
        };
        if (betLines.find((item) => item.id === quickBetId)) {
            alert(t('LB_BS_ADDTOSLIP_HR_ERR_MSG1'));
            return;
        }
        if(addToBetSlip([betObj]).acceptResult){
            setFOSelectedBet({});
        }
    };

    const addCWINSelect = (selections, context, alupData) => {
        const mtg = context?.content.meeting;
        const raceNo = alupData?.raceNo || context.content.raceNo;
        const selectedCombId = Object.keys(selections).filter((i) => selections[i]);
        const betObj = {};
        betObj.prod = 'HR';
        betObj.betType = alupData?.betType || context.content.page;
        betObj.subType = racingConfig.hasSubType.includes(betObj.betType) ? context.content?.subMethod?.type : undefined;
        betObj.venueid = context.content.venue;
        betObj.dayid = mtg.dateOfWeek;
        betObj.poolid = context.content.page;
        betObj.noOfBets = CalculateBet(mtg, GetCalBetObj());
        betObj.isRandom = GetBetSelsRandom();
        betObj.raceno = raceNo;
        betObj.betList = [];
        betObj.meetingId = mtg.id;
        betObj.id = Date.now().toString();
        betObj.useFlexiUi = IsFlexibetEnabled(betObj.betType);
        betObj.isFlexi = context.content.flexibet;
        if (context.content.unitBet != '-') betObj.unitBet = parseInt(context.content.unitBet);
        if (context.content.betTotal != '-') betObj.betTotal = parseInt(context.content.betTotal);

        selectedCombId.forEach((combID) => {
            const [betType, raceNo, itemName, composite] = combID.split('_');
            const poolId = `${mtg.id}${betType}${raceNo}`;
            const currentPool = mtg.pmPools.find((pool) => pool.id === poolId);

            const currentComb = currentPool?.cWinSelections?.find((item) => item.composite === composite);
            const combItem = [];
            currentComb.starters.forEach((runnerNo) => {
                combItem.push(getRunnerDetails(raceNo, runnerNo));
            });
            betObj.betList.push({
                composite: currentComb.composite,
                name_ch: currentComb.name_ch,
                name_en: currentComb.name_en,
                combs: combItem
            });
        });
        sortRunners(betObj);
        if (addToBetSlip([betObj]).acceptResult) {
            clearCheckboxes();
        }
    };

    const updateBankersBySubtype = (obj) => {
        switch (obj.betType) {
            case 'FCT':
            case 'TCE':
            case 'QTT':
                switch (obj.subType) {
                    case 'S':
                        obj.combs = [...obj.bankers[0], ...obj.bankers[1], ...obj.bankers[2], ...obj.combs];
                        obj.bankers[0] = [];
                        obj.bankers[1] = [];
                        obj.bankers[2] = [];
                        break;
                    case 'B':
                    case 'BM':
                        obj.bankers[0] = [...obj.bankers[0], ...obj.bankers[1], ...obj.bankers[2]];
                        obj.bankers[1] = [];
                        obj.bankers[2] = [];
                        break;
                }
                break;
            case 'IWN':
                obj.combs = [...obj.bankers[0], ...obj.combs];
                obj.bankers[0] = [];
                break;
        }
    };

    const getRunnerDetails = (raceNo, runnerNo) => {
        const meeting = context.content.meeting;
        let runner = {};
        if (runnerNo === 'F') {
            runner = {
                no: 'F',
                name_en: t('LB_RC_POOL_FULL_EN'),
                name_ch: t('LB_RC_POOL_FULL_CH')
            };
        } else {
            runner = meeting.races.find((race) => race.no == raceNo)?.runners.find((runner) => runner.no == runnerNo);
        }
        return runner;
    };

    const sortRunners = (betObj) => {
        if (['CWA', 'CWB', 'CWC', 'CWAALUP', 'CWAALLALUP'].includes(betObj.betType)) {
            betObj?.betList?.sort((a, b) => {
                return a.composite > b.composite ? 1 : -1;
            });
            betObj?.combs?.sort((a, b) => {
                return a.composite > b.composite ? 1 : -1;
            });
            return;
        }
        betObj.bankers?.map((banker) => banker.sort((a, b) => (Number(a.no) > Number(b.no) ? 1 : -1)));
        betObj.combs?.sort((a, b) => {
            return Number(a.no) > Number(b.no) ? 1 : -1;
        });
        betObj.betList?.map((item) => {
            item.bankers?.map((banker) => banker.sort((a, b) => (Number(a.no) > Number(b.no) ? 1 : -1)));
            item.combs?.sort((a, b) => (Number(a.no) > Number(b.no) ? 1 : -1));
        });
    };

    useImperativeHandle(ref, () => ({
        handleClickAddSlipBtn
    }));

    return (
        <div className="AddToSlip" onClick={handleClickAddSlipBtn} ref={btnRef}>
            {t('LB_ADDTOSLIP')}
        </div>
    );
};
export default forwardRef(AddSlipBtn);
